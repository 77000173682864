import { createSlice } from '@reduxjs/toolkit';

  // 초기설정
  const initialState = {
    active: '',
    date: ''
  };

  // 이벤트설정
export const OpenSettingReducers = createSlice({
  name: "OpenSettingValue",
  initialState,
  reducers: {
    OpenSettingValue: (state, action) => {
      state = action.payload
    }
  },
});

export const { OpenSettingValue } = OpenSettingReducers.actions;
export default OpenSettingReducers.reducer;