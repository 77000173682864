// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ReserveCalendar_btnBox__1YrCx {\n  margin-bottom: 1.5rem;\n}\n.ReserveCalendar_btnBox__1YrCx .ReserveCalendar_FiberManualRecordIconOn__3GLdn {\n  margin-left: 8px;\n  font-size: 11px;\n  color: green;\n}\n.ReserveCalendar_btnBox__1YrCx .ReserveCalendar_FiberManualRecordIconOff__2Q-FO {\n  margin-left: 8px;\n  font-size: 11px;\n  color: red;\n}", "",{"version":3,"sources":["webpack://src/pages/ReserveCalendar/index.module.scss"],"names":[],"mappings":"AAAA;EAAQ,qBAAA;AAER;AADE;EAAyB,gBAAA;EAAgB,eAAA;EAAe,YAAA;AAM1D;AALE;EAA0B,gBAAA;EAAgB,eAAA;EAAe,UAAA;AAU3D","sourcesContent":[".btnBox{margin-bottom:1.5rem;\n  .FiberManualRecordIconOn{margin-left:8px;font-size:11px;color:green;}\n  .FiberManualRecordIconOff{margin-left:8px;font-size:11px;color:red;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBox": "ReserveCalendar_btnBox__1YrCx",
	"FiberManualRecordIconOn": "ReserveCalendar_FiberManualRecordIconOn__3GLdn",
	"FiberManualRecordIconOff": "ReserveCalendar_FiberManualRecordIconOff__2Q-FO"
};
export default ___CSS_LOADER_EXPORT___;
