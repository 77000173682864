// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CurriculumReservationListModals_title__NeWP5 {\n  padding: 1rem 0 2rem;\n  font-size: 1.14844rem;\n  line-height: 1.5;\n  font-weight: 600;\n  color: #1e2022;\n  text-align: center;\n}\n\n.CurriculumReservationListModals_Pagination__1tER5 {\n  padding-bottom: 1rem;\n}\n\n.CurriculumReservationListModals_btnBox__1DHbO {\n  padding-top: 1.75rem;\n  text-align: right;\n}", "",{"version":3,"sources":["webpack://src/components/ReserveCalendar/CurriculumReservationListModals/index.module.scss"],"names":[],"mappings":"AACA;EAAO,oBAAA;EAAoB,qBAAA;EAAqB,gBAAA;EAAgB,gBAAA;EAAgB,cAAA;EAAc,kBAAA;AAM9F;;AALA;EAAY,oBAAA;AASZ;;AARA;EAAQ,oBAAA;EAAoB,iBAAA;AAa5B","sourcesContent":["\n.title{padding:1rem 0 2rem;font-size:1.14844rem;line-height:1.5;font-weight:600;color:#1e2022;text-align:center;}\n.Pagination{padding-bottom:1rem;}\n.btnBox{padding-top:1.75rem;text-align:right;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "CurriculumReservationListModals_title__NeWP5",
	"Pagination": "CurriculumReservationListModals_Pagination__1tER5",
	"btnBox": "CurriculumReservationListModals_btnBox__1DHbO"
};
export default ___CSS_LOADER_EXPORT___;
