import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { curriculumDataLoad, curriculumDataSave } from 'lib/Api';

// style
import Style from './index.module.scss';

// project import
import CurriculumManagementForm from './CurriculumManagementForm';

// mui
import { Tabs } from 'antd';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

const CurriculumManagement = (props) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.CalendarDateReducers);
  const [curriculumType, setCurriculumType] = useState('boutique');
  const [dataLoadValue, setDataLoadValue] = useState();
  const [apiCallBool, setApiCallBool] = useState(false);
  const [parseData, setParseData] = useState({});

  const ApiDataLoad = async (calenderYYValue,calenderMMValue,calenderDDValue) => {
    if(apiCallBool) return;
    setApiCallBool(true)

    try {
      let formData = {};
      formData.selectType = curriculumType;
      if(count){
        formData.year = calenderYYValue ? calenderYYValue : count.date.year;
        formData.month = calenderMMValue ? calenderMMValue : count.date.month;
        formData.date = calenderDDValue ? calenderDDValue : count.date.day;
      }
      if(!formData.year || !formData.month || !formData.date){
        const nd = new Date();
        formData.year = nd.getFullYear()
        formData.month = nd.getMonth()+1
        formData.date = nd.getDate()
      }
      setParseData(formData);
      // console.log('formData', formData)
      const _data = await curriculumDataLoad(formData);
      setApiCallBool(false)
      if(_data.data.statusCode === 200){
        // console.log(_data.data.reservationTimes)
        setDataLoadValue(_data.data.reservationTimes);
      }else {
        alert(_data.data.error);
      }
    }catch(e){
      setApiCallBool(false)
      console.log(e);
    }
  };

  const ApiDataSave = async (onFinishValue, calenderYYValue,calenderMMValue, calenderDDValue, selectTime) => {
    try {
      let formData = {};
      formData.selectType = curriculumType;
      if(count){
        formData.year = calenderYYValue ? calenderYYValue : count.date.year;
        formData.month = calenderMMValue ? calenderMMValue : count.date.month;
        formData.date = calenderDDValue ? calenderDDValue : count.date.day;
      };
      if(!selectTime){
        alert('시간을 선택해주세요.')
        return;
      }
      // if(onFinishValue.TeamNumber<1){
      //   alert('예약가능한 팀 숫자를 입력해주세요.')
      //   return;
      // }
      if(onFinishValue){
        formData.timeData = selectTime;
        formData.team = onFinishValue.TeamNumber;
        formData.person = onFinishValue.PersonnelNumber;
        formData.reserveBool = onFinishValue.ExcludingWeekends;
      };

      // console.log(formData)
      const _data = await curriculumDataSave(formData);
      // console.log(_data);
      if(_data.data.statusCode === 200){
        dispatch(ModalsValue({type:1, show:true, text:"Alert_10", okfunc:"Alert_10"}));
        ApiDataLoad(parseData.year,parseData.month,parseData.date);
      }else {
        alert(_data.data.error);
      }
      
    }catch(e){
      console.log(e);
    };
  };

  const onChangeTabs = (key) => setCurriculumType(key);

  const loadValue = () => {
    ApiDataLoad();
  };

  const saveValue = (onFinishValue, calenderYYValue,calenderMMValue,calenderDDValue, selectTime) => {
    ApiDataSave(onFinishValue, calenderYYValue,calenderMMValue, calenderDDValue, selectTime);
  };

  useEffect(()=>{
    // console.log(count)
    ApiDataLoad()
  },[count, curriculumType]);

  return (
    <>
      <h2 className='contentsTitle'>{props.metaTitle}</h2>
      
      <Tabs className={Style['Tabs']}
        defaultActiveKey="1"
        onChange={onChangeTabs}
        items={[
          {
            key: 'boutique',
            label: '부띠크 방문',
            children: <CurriculumManagementForm DataLoadFun={loadValue} DataLoadValue={dataLoadValue} DataSave={saveValue} />
          },
          {
            key: 'cafe',
            label: 'CAFÉ DIOR',
            children: <CurriculumManagementForm DataLoadFun={loadValue} DataLoadValue={dataLoadValue} DataSave={saveValue} />
          },
          {
            key: 'docent',
            label: '도슨트 프로그램',
            children: <CurriculumManagementForm DataLoadFun={loadValue} DataLoadValue={dataLoadValue} DataSave={saveValue} />
          },
          // {
          //   key: 'ladydior',
          //   label: 'LADY DIOR CELEBRATION',
          //   children: <CurriculumManagementForm DataLoadFun={loadValue} DataLoadValue={dataLoadValue} DataSave={saveValue} />
          // }
        ]}
      />
    </>
  )
}

export default CurriculumManagement;