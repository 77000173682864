import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// utils
import { useTranslation } from "react-i18next";

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// material-ui
import { Modal, Button } from 'antd';

const ConfirmModals = ({ setOpen, open }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [buttons, setButtons] = useState([])
  const count = useSelector((state) => state.ModalsReducers.ModalsValue);

  const Alert_02 =()=> {
    // console.log("Alert_02")
  };
  const Alert_05 =()=> {
    // console.log("Alert_05")
    // dispatch(ModalsValue({type:1, show:true, text:"Alert_03"}))
    navigate(-1);
  };
  const Alert_07 =()=> {
    // console.log("Alert_07")
  };
  const Alert_08 =()=> {
    // console.log("Alert_08")
  };
  const Alert_15 =()=> {
    // console.log("Alert_15")
  };

  const handleOk = () => {
    if(count.okfunc === 'Alert_02') Alert_02();
    if(count.okfunc === 'Alert_05') Alert_05();
    if(count.okfunc === 'Alert_07') Alert_07();
    if(count.okfunc === 'Alert_08') Alert_08();
    if(count.okfunc === 'Alert_15') Alert_15();
    dispatch(ModalsValue({show:false}));
    setOpen(false);
  };

  const handleCancel = () => {
    dispatch(ModalsValue({show:false}));
    setOpen(false);
  };
  useEffect(()=>{
    count.type === 1 ? (
      setButtons([<Button key={102} type="primary" onClick={handleOk}>{t("Btn_confirm")}</Button>])
    ) : (
      setButtons([
        <Button key={103} type="primary" onClick={handleOk}>{t("Btn_confirm")}</Button>,
        <Button key={104} onClick={handleCancel}>{t("Btn_cancel")}</Button>
      ])
    )
  },[count])

  return (
    <>
      <Modal
        className='confirmModals'
        open={open}
        closable={false}
        centered={true}
        width={400}
        key={1}
        footer= {buttons}
      >
        <p className="text" dangerouslySetInnerHTML={{__html:t(count.text)}}></p>
      </Modal>
    </>
  )
}

export default ConfirmModals;