import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { noticeBoardList } from 'lib/Api';
import { addToZero } from 'lib/common';

// style
import Style from './index.module.scss';

// mui
import { InputLabel } from '@mui/material';
import { Col, Row, Select, Input, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

// project import
import TableList from 'components/items/TableList';
import Pagination from 'components/items/Pagination';

const dataTitle = [
  'No', 
  '제목', 
  '내용', 
  '노출여부', 
  '등록일'
]

const Notice = (props) => {
  const navigate = useNavigate();
  const [boardList, setBoardList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchText, setSearchText] = useState('');
  const [searchShow, setSearchShow] = useState('All');
  const [searchValue, setSearchValue] = useState();

  const limit = 10;
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const noticeList = async () => {
    try {
      setError(null); // error 초기화
      setLoading(true); // loading 상태 변경
      const _data = await noticeBoardList();
      if(_data.data.statusCode === 200){
        const parseBoard = _data.data.boardData.map((item) => {
          const tempDate = new Date(item.regDate)
          item.regDate = `${tempDate.getFullYear()}-${addToZero(tempDate.getMonth()+1,'00')}-${addToZero(tempDate.getDate(),'00')} ${addToZero(tempDate.getHours(),'00')}:${addToZero(tempDate.getMinutes(),'00')}`
          return item;
        })
        setBoardList(parseBoard);
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
    }catch(e){
      setError(e);
    }
    setLoading(false);
  };

  const noticeWrite =()=> {
    navigate("/Notice/View/Add", {state:{ Key: "Add" }});
  };

  const TitleTextOnChange = (e) => {setSearchText(e.target.value)};
  const showChange = (e) => {setSearchShow(e)};

  const buttonSearch =()=> {
    const allFilter = boardList.filter((data) => {
      return (data.title + data.contents).toLowerCase().includes(searchText.toLowerCase()) && 
              (searchShow === 'All' || data.visibleState.toLowerCase().includes(searchShow.toLowerCase()));     
    });

    setSearchValue(allFilter);
  };

  useEffect(()=>{noticeList()},[]);

  if (loading) return <div>로딩중..</div>; 
  if (error) return <div>에러가 발생했습니다</div>;
  if (!boardList) return null;

  return (
    <>
      <Helmet>
        <title>{props.metaTitle}</title>
      </Helmet>
      <h2 className='contentsTitle'>{props.metaTitle}</h2>

      <div className={Style['searchFrom']}>
        <Row align="bottom">
          <Col span={8}>
            <InputLabel>제목/내용</InputLabel>
            <Input allowClear onChange={TitleTextOnChange} />
          </Col>
          <Col xs={{ span: 7, offset: 1 }}>
            <InputLabel>노출여부</InputLabel>
            <Select
              defaultValue="전체"
              onChange={showChange}
              style={{width:150}}
              options={[
                {value: 'All', label: '전체'},
                {value: 'Y', label: '노출'},
                {value: 'N', label: '비노출'}
              ]}
            />
          </Col>
          <Col xs={{ span: 7, offset: 1 }} align="right"><Button onClick={buttonSearch}>검색</Button></Col>
        </Row>
      </div>

      <Row className={Style['noticeWriteRow']}>
        <Col span={24} align="right">
          <Button type="primary" onClick={noticeWrite}>등록하기 <PlusCircleOutlined /></Button>
        </Col>
      </Row>
      
      <TableList 
        listName="Notice" dataTitle={dataTitle} 
        data={searchValue ? (searchValue):(boardList)}
        offset={offset} limit={limit} 
      />

      <Pagination 
        total={searchValue ? (searchValue.length):(boardList.length)}
        limit={limit}
        setPage={setPage}
      />
    </>
  )
}

export default Notice;