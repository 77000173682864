import { createSlice } from '@reduxjs/toolkit';

  // 초기설정
  const initialState = {
    Calendar: {
      type:'month' // month, day - 월간, 일간 선택
    },
    date: {
      year:'',
      month:'',
      day:'',
      Time:''
    },
    minute:'',
    second:''
  };

  // 이벤트설정
export const CalendarDateReducers = createSlice({
  name: "CalendarValue",
  initialState,
  reducers: {
    CalendarTypeValue: (state, action) => {
      state.Calendar = action.payload
    },
    dateValue: (state, action) => {
      state.date = action.payload
    }
  },
});

export const { CalendarTypeValue, dateValue } = CalendarDateReducers.actions;
export default CalendarDateReducers.reducer;