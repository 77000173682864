import React from 'react';
import Routes from 'routes'
import 'styles/layout.scss';

const App =()=> {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;