import React from 'react';

// mui
import Pagination from '@mui/material/Pagination';

const Paging = ({total, limit, setPage}) => {
  let totalPage = Math.ceil(total / limit);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {totalPage ? (
        <Pagination 
          count={totalPage} 
          color="primary" 
          shape="rounded"
          onChange={handleChangePage}
          style={{display:'flex', paddingTop:'2.625rem', justifyContent:'center'}}
        />
      ):(
        <></>
      )}
      
    </>
  )
}

export default Paging;