import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { curriculumRegistration } from 'lib/Api';

// style
import Style from './index.module.scss';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// project import
import CurriculumRegistrationFrom from './CurriculumRegistrationFrom';

// mui
import { Row, Col, Form, Button, Space } from 'antd';

const CurriculumRegistration = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const count = useSelector((state) => state.CalendarDateReducers);
  const [timeDocent, setTimeDocent] = useState();
  const [timeBoutique, setTimeBoutique] = useState();
  const [timeCafe, setTimeCafe] = useState();
  const [timeLadyDior, setTimeLadyDior] = useState();
  
  const setCurriclum = async (e) => {
    // console.log(e.DocentTeam === '', e.DocentTeam=== undefined, e.DocentTeam=== 'undefined',!e.DocentTeam, Number(e.docentTeam)<1);
    if(!count.date.year) {alert('설정연도를 입력해주세요.'); return;}
    if(!count.date.month) {alert('설정월을 입력해주세요.'); return;}
    if(e.DocentTeam === undefined) {alert('도슨트 팀숫자를 설정해 주세요.'); return;}
    if(e.DocentJoin === undefined) {alert('도슨트 팀당 인원을 설정해 주세요.'); return;}
    // if(timeDocent.length<1) {alert('도슨트 설정 시간을 선택해주세요.'); return;}
    if(e.BoutiqueTeam === undefined) {alert('부띠끄 팀숫자를 설정해 주세요.'); return;}
    if(e.BoutiqueJoin === undefined) {alert('부띠끄 팀당 인원을 설정해 주세요.'); return;}
    // if(timeBoutique.length<1) {alert('부띠끄 설정 시간을 선택해 주세요.'); return;}
    if(e.CafeTeam === undefined) {alert('카페 팀숫자를 설정해 주세요.'); return;}
    if(e.CafeJoin === undefined) {alert('카페 팀당 인원을 설정해 주세요.'); return;}
    // if(timeCafe.length<1) {alert('카페 설정 시간을 선택해주세요.'); return;}
    // if(e.LadyDiorTeam === undefined) {alert('레이디 디올 팀숫자를 설정해 주세요.'); return;}
    // if(e.LadyDiorJoin === undefined) {alert('레이디 디올 팀당 인원을 설정해 주세요.'); return;}


    try {
      let formData = {};
      if(e){
        console.log(e)
        formData.year = count.date.year;
        formData.month = count.date.month;

        formData.docentTeam = e.DocentTeam;
        formData.docentPerson = e.DocentJoin;
        formData.docentWeek = e.DocentWeekend;
        formData.docentTimes = timeDocent;

        formData.boutiqueTeam = e.BoutiqueTeam;
        formData.boutiquePerson = e.BoutiqueJoin;
        formData.boutiqueWeek = e.BoutiqueWeekend;
        formData.boutiqueTimes = timeBoutique;

        formData.cafeTeam = e.CafeTeam;
        formData.cafePerson = e.CafeJoin;
        formData.cafeWeek = e.CafeWeekend;
        formData.cafeTimes = timeCafe;

        // formData.ladyDiorTeam = e.LadyDiorTeam;
        // formData.ladyDiorPerson = e.LadyDiorJoin;
        // formData.ladyDiorWeek = e.LaydDiorWeekend;
        // formData.ladyDiorTimes = timeLadyDior;

        //도슨트 관련 데이터 클린징
        if(e.DocentTeam === undefined) formData.docentTeam = 0;
        if(e.DocentJoin === undefined) formData.docentPerson = 0;
        if(formData.docentWeek === true) {
          formData.docentWeek = false 
        }else{
          formData.docentWeek = true
        } 
        if(timeDocent === undefined) formData.docentTimes = null;

        //부띠끄관련 데이터 클린징
        if(e.BoutiqueTeam === undefined) formData.boutiqueTeam = 0;
        if(e.BoutiqueJoin === undefined) formData.boutiquePerson = 0;
        if(formData.boutiqueWeek === true) {
          formData.boutiqueWeek = false 
        }else{
          formData.boutiqueWeek = true
        }
        if(timeBoutique === undefined) formData.boutiqueTimes = null;

        //카페 관련 데이터 클린징
        if(e.CafeTeam === undefined) formData.cafeTeam = 0;
        if(e.CafeJoin === undefined) formData.cafePerson = 0;
        if(formData.cafeWeek === true) {
          formData.cafeWeek = false 
        }else{
          formData.cafeWeek = true
        }
        if(timeCafe === undefined) formData.cafeTimes = null;

        //레이디디올 관련 데이터 클린징
        // if(e.LadyDiorTeam === undefined) formData.ladyDiorTeam = 0;
        // if(e.LadyDiorJoin === undefined) formData.ladyDiorPerson = 0;
        // if(formData.ladyDiorWeek === true) {
        //   formData.ladyDiorWeek = false 
        // }else{
        //   formData.ladyDiorWeek = true
        // }
        // if(timeLadyDior === undefined) formData.ladyDiorTimes = null;
        
      };
      const _data = await curriculumRegistration(formData);
      if(_data.data.statusCode === 200){
        //alert(_data.data.message);
        dispatch(ModalsValue({type:1, show:true, text:"Alert_10"}))
        navigate("/ReserveCalendar");
      }else {
        dispatch(ModalsValue({type:1, show:true, text:"Alert_13"}))
        // alert(_data.data.error);
      }
      // console.log(_data);
    }catch(e){
      console.log(e);
    }
  };

  const setTimeValue = (type, values) => {
    if(type === 'docent'){
      setTimeDocent(values);
    }else if(type === 'boutique'){
      setTimeBoutique(values);
    }else if(type === 'cafe'){
      setTimeCafe(values);
    } else if(type === 'ladydior'){
      setTimeLadyDior(values);
    };
  };

  const ButtonCancel =()=> dispatch(ModalsValue({type:2, show:true, text:"Alert_05", okfunc:"Alert_05"}));
  const onFinish = (values) => setCurriclum(values);
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <h2 className='contentsTitle'>{props.metaTitle}</h2>

      <Form
        className={Style['FormBox']}
        name="wrap"
        labelWrap
        colon={false}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row className={Style['Row']}>
          <Col span={5}>도슨트 프로그램</Col>
          <Col span={19}>
            <CurriculumRegistrationFrom CurriculumType="docent" timeFun={setTimeValue} />
          </Col>
        </Row>
        <Row className={Style['Row']}>
          <Col span={5}>부띠크 방문</Col>
          <Col span={19}>
            <CurriculumRegistrationFrom CurriculumType="boutique" timeFun={setTimeValue} />
          </Col>
        </Row>
        <Row className={Style['Row']}>
          <Col span={5}>CAFÉ DIOR</Col>
          <Col span={19}>
            <CurriculumRegistrationFrom CurriculumType="cafe" timeFun={setTimeValue} />
          </Col>
        </Row>
        {/* <Row className={Style['Row']}>
          <Col span={5}>Lady Dior Celebration</Col>
          <Col span={19}>
            <CurriculumRegistrationFrom CurriculumType="ladydior" timeFun={setTimeValue} />
          </Col>
        </Row> */}
        <Row>
          <Col span={24} align="right">
            <Form.Item>
              <Space>
                <Button danger onClick={ButtonCancel}>취소</Button>
                <Button type="primary" htmlType="submit">설정하기</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default CurriculumRegistration;