import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import dayjs from "dayjs";
// style
import Style from "./index.module.scss";

// project import
import Calendar from "components/items/Calendar";
import { addToZero } from "lib/common";
import { openInfo, checkReport } from "lib/Api";

// store
import { ModalsValue } from "store/reducers/ModalsReducers";

// mui
import { InputLabel } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Space, Button, Row, Col, DatePicker } from "antd";
import { DownloadOutlined, PlusCircleOutlined, SettingOutlined } from "@ant-design/icons";

const ReserveCalendar = (props) => {
  const todayStr = moment().format("YYYY-MM-DD");
  const dateFormat = "YYYY-MM-DD HH:mm";
  const searchStart = moment(`${todayStr} 00:00`).format(dateFormat);
  const searchEnd = moment(`${todayStr} 23:59`).format(dateFormat);
  const dispatch = useDispatch();
  const count = useSelector((state) => state.CalendarDateReducers);
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openSettingsMode, setOpenSettingsMode] = useState("Off");
  const [curriculumMode, setCurriculumMode] = useState(false);
  const [searchDays, setSearchDays] = useState({ startD: searchStart, endD: searchEnd });

  const { RangePicker } = DatePicker;

  const changeSheetDate = (date, dateStrings) => {
    const startDay = moment(dateStrings[0].split(" ")[0], "YYYY-MM-DD");
    const endDay = moment(dateStrings[1].split(" ")[0], "YYYY-MM-DD");
    const startTime = moment(dateStrings[0].split(" ")[1], "HH:mm");
    const endDayTime = moment(dateStrings[1].split(" ")[1], "HH:mm");
    let result = { startD: startDay._i + " " + startTime._i, endD: endDay._i + " " + endDayTime._i };
    if (!startDay._i) return;

    setSearchDays({ ...result });
  };

  const reportDownload = () => {
    if (loading) return;
    setLoading(true);
    fetch(`${process.env.REACT_APP_BASEURL}cms/report/daily?startD=${searchDays.startD}&endD=${searchDays.endD}`).then(
      (response) => {
        response.blob().then((blob) => {
          const date = moment(searchDays.endD)
          const now = moment().subtract(1,'days').format('YYMMDD')
          const month = date.month()
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `디올성수 ${month + 1}월 일일 리포트_${now}.xlsx`;
          a.click();
          setLoading(false);
        });
      }
    );
  };
  useEffect(() => {
    if (count.date.year === "") return;
    checkDownDate();
  }, [count]);
  const checkDownDate = async () => {
    try {
      const _data = await checkReport();
      if (_data.data.statusCode === 200) {
        const startD = moment(_data.data.downDate.start_date).format(dateFormat);
        const endD = moment(_data.data.downDate.end_date).format(dateFormat);
        changeSheetDate(null, [startD, endD]);
      } else {
        alert(_data.data.error);
      }
    } catch (e) {
      alert(e);
    }
  };

  const OpenSettings = () => {
    let okfunc = openSettingsMode;
    let date = "2023.11.11 12:00:00";
    dispatch(
      ModalsValue({
        type: "basics",
        show: true,
        text: "OpenSettingsPasswordModals",
        okfunc: okfunc,
        date: date,
      })
    );
  };

  const CurriculumManagement = () => {
    if (!curriculumMode) {
      dispatch(ModalsValue({ type: 1, show: true, text: "Alert_14" }));
      return;
    }
    navigate("/ReserveCalendar/CurriculumManagement");
  };

  const curriculumRegistration = () => {
    if (curriculumMode) {
      dispatch(ModalsValue({ type: 1, show: true, text: "Alert_13" }));
      return;
    }
    navigate("/ReserveCalendar/CurriculumRegistration");
  };
  const openInfoLoad = async () => {
    try {
      const nowDate = new Date();
      const formData = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
        time: nowDate.getHours() + ":" + nowDate.getMinutes(),
      };
      const _data = await openInfo(formData);
      if (_data.data.statusCode === 200) {
        if (_data.data.reservationState) {
          setOpenSettingsMode("On");
        }
      } else {
        alert(_data.data.error);
        navigate("/Login");
      }
    } catch (e) {
      alert(e);
    }
  };
  useEffect(() => {
    openInfoLoad();
  }, []);
  useEffect(() => {
    console.log(curriculumMode);
  }, [curriculumMode]);
  return (
    <>
      <Helmet>
        <title>{props.metaTitle}</title>
      </Helmet>
      <h2 className="contentsTitle">{props.metaTitle}</h2>

      <Row className={Style["btnBox"]}>
        <Col span={12}>
          <Space>
            <InputLabel />
            <RangePicker
              value={[dayjs(searchDays.startD, dateFormat), dayjs(searchDays.endD, dateFormat)]}
              className={Style["RangePicker"]}
              onChange={changeSheetDate}
              showTime
              use12Hours={false}
              format="YYYY-MM-DD HH:mm"
              minuteStep={60}
            />
            <Button onClick={reportDownload}>
              일일 리포트
              <DownloadOutlined />
            </Button>
          </Space>
        </Col>
        <Col span={12} align="right">
          <Space>
            <Button onClick={OpenSettings}>
              오픈 설정
              {openSettingsMode === "On" ? (
                <FiberManualRecordIcon className={Style["FiberManualRecordIconOn"]} />
              ) : (
                <FiberManualRecordIcon className={Style["FiberManualRecordIconOff"]} />
              )}
            </Button>
            <Button onClick={CurriculumManagement}>
              커리큘럼 관리
              <SettingOutlined />
            </Button>
            <Button onClick={curriculumRegistration}>
              커리큘럼 등록
              <PlusCircleOutlined />
            </Button>
          </Space>
        </Col>
      </Row>

      <Calendar setCurriculumMode={setCurriculumMode} />
    </>
  );
};

export default ReserveCalendar;
