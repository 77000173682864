import React from 'react';
import LayoutIndex from 'layouts';

// project import
import ReserveCalendar from "pages/ReserveCalendar";
import OpenSettings from "components/ReserveCalendar/OpenSettings";
import CurriculumManagement from "components/ReserveCalendar/CurriculumManagement";
import CurriculumRegistration from "components/ReserveCalendar/CurriculumRegistration";
import ReserveList from "pages/ReserveList";
import Notice from "pages/Notice";
import NoticeView from "pages/Notice/View";
import TextManagement from "pages/TextManagement";
import TextManagementView from "pages/TextManagement/View";

// ==============================|| AUTH ROUTING ||============================== //
const adminRoutes = {
  path: '/',
  element: <LayoutIndex layout="admin" />,
  children: [
    // 예약 캘린더
    {
      path: "ReserveCalendar",
      element: <ReserveCalendar metaTitle='예약 캘린더' />,
    },
    {
      path: "ReserveCalendar/OpenSettings",
      element: <OpenSettings metaTitle='오픈 설정' />,
    },
    {
      path: "ReserveCalendar/CurriculumManagement",
      element: <CurriculumManagement metaTitle='커리큘럼 관리' />,
    },
    {
      path: "ReserveCalendar/CurriculumRegistration",
      element: <CurriculumRegistration metaTitle='커리큘럼 등록하기' />,
    },

    // 예약 리스트
    {
      path: "ReserveList",
      element: <ReserveList metaTitle='예약 리스트' />,
    },

    // 공지사항
    {
      path: "Notice",
      element: <Notice metaTitle='공지사항' />,
    },
    {
      path: "Notice/View/:id",
      element: <NoticeView metaTitle='공지사항' />,
    },

    // 텍스트 관리
    {
      path: "TextManagement",
      element: <TextManagement metaTitle='텍스트 관리' />,
    },
    {
      path: "TextManagement/View/:id",
      element: <TextManagementView metaTitle='텍스트 관리' />,
    }
  ]
};

export default adminRoutes;
