import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

// mui
import Icon from '@mdi/react';
import { mdiCalendarMultiselect, mdiReceiptTextOutline, mdiCheckboxBlankBadgeOutline, mdiCogOutline } from '@mdi/js';
import { Layout, Menu } from 'antd';
const { Sider } = Layout;

const items=[
  {
    key: '1',
    icon: <Icon path={mdiCalendarMultiselect} size='18px' />,
    label: '예약 캘린더',
    to: '/ReserveCalendar',
  },
  {
    key: '2',
    icon: <Icon path={mdiReceiptTextOutline} size='18px' />,
    label: '예약 리스트',
    to: '/ReserveList',
  },
  {
    key: '3',
    icon: <Icon path={mdiCheckboxBlankBadgeOutline} size='18px' />,
    label: '공지사항',
    to: '/Notice',
  },
  {
    key: '4',
    icon: <Icon path={mdiCogOutline} size='18px' />,
    label: '텍스트 관리',
    to: '/TextManagement',
  },
];

const Side =({ collapsed })=> {
  let navigate = useNavigate();
  const count = useSelector((state) => state.PageInfoReducers.PageValue);
  const [selectedKeys, setSelectedKeys] = useState();
  
  const itemHandler = (e) => {
    const target = items[e.key-1];
    navigate(target.to);
  };

  useEffect(()=>{
    if(!count) return;
    setSelectedKeys(count.key);
  },[count]);

  return(
    <>
      <Sider width={260} collapsedWidth={95} trigger={null} collapsible collapsed={collapsed}>
        <Menu
          theme="dark"
          mode="inline"
          onClick={(e) => itemHandler(e)}
          defaultSelectedKeys={count.key}
          items={items}
          selectedKeys={selectedKeys}
        />
      </Sider>
    </>
  );
};

export default Side;