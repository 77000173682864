import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';

// style
import Style from './index.module.scss';

// store
import { CalendarTypeValue, dateValue } from 'store/reducers/ReserveCalendar/CalendarDateReducers';

// mui
import { Button, Row, Col, Radio, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CustomToolbar = ({ date, label, view, onView, onNavigate }) => {
  const dispatch = useDispatch();
  const [calendarType, setCalendarType] = useState('month');
  const [click, setClick] = useState(false);
  const year = moment(date).format("YYYY");
  const month = moment(date).format("MM");
  const day = moment(date).format("DD");
  const YYMM = { month: month, year: year, day: day };
  const handleMonthClick = () => {
    onView('month');
    dispatch(CalendarTypeValue({type:'month'}));
  };
  const handleDayClick = () => {
    onView('day');
    dispatch(CalendarTypeValue({type:'day'}));
  };

  let formattedLabel;
  if (view === 'month') {
    formattedLabel = moment(label).format('YYYY년 M월');
  } else {
    formattedLabel = moment(label).format('M월 D일');
  };

  const calendarTypeOnChange = ({ target: { value } }) => {
    setCalendarType(value);
  };

  const navigate = (action) => {
      onNavigate(action);
      if (!click) setClick(true);
      else setClick(false);
    };

    useEffect(() => {
      dispatch(dateValue({year:YYMM.year, month:YYMM.month, day:YYMM.day}));
    }, [click]);

    // 
  return (
    <>
      <Row className={Style['btnBox']}>
        <Col span={12}>
          <Space size={15}>
            <Button onClick={navigate.bind(null, "TODAY")}>Today</Button>
            <Button className={Style['ButtonPrev']} shape="circle" type="text" onClick={navigate.bind(null, "PREV")}><LeftOutlined /></Button>
            <Button className={Style['ButtonNext']} shape="circle" type="text" onClick={navigate.bind(null, "NEXT")}><RightOutlined /></Button>

            <strong className={Style['formattedLabel']}>{formattedLabel}</strong>
          </Space>
        </Col>
        <Col span={12} align="right">
          <Radio.Group optionType="button" buttonStyle="solid" onChange={calendarTypeOnChange} value={calendarType}>
            <Radio label="month" value="month" onClick={handleMonthClick}>월간</Radio>
            <Radio label="day" value="day" onClick={handleDayClick}>일간</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </>
  )
}

export default CustomToolbar;