import React, { useState, useEffect } from 'react';
import moment from 'moment';
import dayjs from "dayjs";
import { useDispatch, useSelector } from 'react-redux';
import { reservationMonthList, reservationDayList, checkCurriculum } from 'lib/Api';

// style
import "styles/calendar.scss";

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// mui
import { Calendar, momentLocalizer } from 'react-big-calendar';

// project import
import CustomToolbar from './CustomToolbar';
import { Calculate } from '@mui/icons-material';

const Calendars = ({setCurriculumMode}) => {
  const dispatch = useDispatch();
  const localizer = momentLocalizer(moment);
  const count = useSelector((state) => state.CalendarDateReducers);
  const modalText = useSelector((state) => state.ModalsReducers.ModalsValue.text);

  const [error, setError] = useState(null);

  const [eventsCalendarType, setEventsCalendarType] = useState('month');
  const [apiMonthList, setApiMonthList] = useState();
  const [apiDayList, setApiDayList] = useState();
  const [monthData, setMonthData] = useState();
  const [dayData, setDayData] = useState();
  const [loadYear, setLoadYear] = useState(dayjs().format("YYYY"));
  const [loadMonth, setLoadMonth] = useState(dayjs().format("MM"));
  const [loadDay, setLoadDay] = useState(dayjs().format("DD"));
  const [isApiCall, setIsApiCall] = useState(false);
  const [isInit, setIsInit] = useState(true);//초기 호출
  const [selectTime, setSelectTime] = useState({
    classtype: "",
    start_ymd: dayjs().format("YYYY-MM-DD"),
    time: null,
    title: "",
  });
  useEffect(()=>{
    if((count.date.year.toString() !== loadYear.toString() || count.date.month.toString() !== loadMonth.toString()) || !isApiCall){
      setLoadYear(count.date.year)
      setLoadMonth(count.date.month)
    }
    if(count.date.day.toString() !== loadDay.toString() || !isApiCall){
      setLoadDay(count.date.day)
    }
  },[count])
  useEffect(()=>{
    if(isApiCall) return;
    MonthListApiCall();
    //여기서 쿼리큘럼 체크
    checkCurriculumApiCall();
  },[loadYear,loadMonth])
  useEffect(()=>{
    if(isApiCall) return;
    DayListApiCall();
  },[loadDay])
  useEffect(()=>{
    checkBlock()
    switch(selectTime.classtype){
      case 'b':
        dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'1', ReserveType:"부띠크 방문", text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:""})) 
        break;
      case 'd':
        dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'3', ReserveType:"도슨트 방문", text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:""}))
        break;
      case 'c':
        dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'2', ReserveType:"CAFÉ DIOR", text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:""}))
        break;
      case 'l':
        dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'4', ReserveType:"LADY DIOR CELEBRATION", text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:""}))
        break;
  
      case 'db':
        dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'1', ReserveType:"부띠크 방문" + selectTime.time, text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:selectTime.time}))
        break;
      case 'dd':
        dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'3', ReserveType:"도슨트 방문", text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:selectTime.time}))
        break;
      case 'dc':
          dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'2', ReserveType:"CAFÉ DIOR", text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:selectTime.time}))
        break;
      case 'dl':
        dispatch(ModalsValue({type:'basics', show:true, ReserveCode:'4', ReserveType:"LADY DIOR CELEBRATION" , text:"CurriculumReservationListModals", date:selectTime.start_ymd, time:selectTime.time}))
        break;
      default:
        break;
    }
    
  },[selectTime])
  useEffect(()=>{
    if(modalText === undefined){
      // let ct = document.querySelector(".rbc-selected")
      // if(ct){
      //   ct.classList.remove('rbc-selected')
      // }
      checkBlock()
    }
  },[modalText])
  const checkCurriculumApiCall = async () => { // 월간 API
    if(isApiCall) return;
    setIsApiCall(true);
    try {
      let formData = {};
      formData.year = loadYear;// count.date.year;
      formData.month = loadMonth;// count.date.month;
      const _data = await checkCurriculum(formData);
      setIsApiCall(false);
      setCurriculumMode(_data.data.curriculumState);
    }catch(e){
      setError(e);
    }
  };

  const MonthListApiCall = async () => { // 월간 API
    if(isApiCall) return;
    setIsApiCall(true);
    try {
      let formData = {};
      formData.year = loadYear;// count.date.year;
      formData.month = loadMonth;// count.date.month;
      const _data = await reservationMonthList(formData);

      console.log(_data.data);



      
      setIsApiCall(false);
      setApiMonthList(_data.data.curriculum);
    }catch(e){
      setError(e);
    }
  };

  const DayListApiCall = async () => { // 일간 API
    try {
      let formData = {};
      formData.year = loadYear;// count.date.year;
      formData.month = loadMonth;// count.date.month;
      formData.day = loadDay;// count.date.day;
      const _data = await reservationDayList(formData);
      let parseArr = []
      for (let defaultKey in _data.data.curriculum) {
        let isSame = false;
        let tempObj = _data.data.curriculum[defaultKey]
        let changeTypeB = false;
        let changeTypeD = false;
        let changeTypeC = false;
        let changeTypeL = false;
        for (let changeKey in _data.data.changedCurriculum) {
          if(defaultKey.toString() === changeKey.toString()){
            isSame = true;
            if(_data.data.changedCurriculum[changeKey].cafeTeam) changeTypeC = true;
            if(_data.data.changedCurriculum[changeKey].docentTeam) changeTypeD = true;
            if(_data.data.changedCurriculum[changeKey].boutiqueTeam) changeTypeB = true;
            if(_data.data.changedCurriculum[changeKey].ladyDiorTeam) changeTypeL = true;
            break;
          }
        }
        tempObj.time = defaultKey;
        if(isSame){
          tempObj.changed = isSame;
          if(changeTypeC)tempObj.changeTypeC = changeTypeC;
          if(changeTypeD)tempObj.changeTypeD = changeTypeD;
          if(changeTypeB)tempObj.changeTypeB = changeTypeB;
          if(changeTypeL)tempObj.changeTypeL = changeTypeL;
        }else{
          tempObj.changed = isSame
        }
        parseArr.push(tempObj)
      }
      console.log(parseArr);
      setApiDayList(parseArr);
    }catch(e){
      setError(e);
    }
  };

  const _monthData =()=> { // 월간 데이터
    const events = [];

    for (var key in apiMonthList) {
      // console.log(key, apiMonthList[key].docentTeam)
      
      if(Number(apiMonthList[key].boutiqueTeam)>0){
        events.push({
          classtype:'b',
          title: `부띠크 방문 ${apiMonthList[key].boutiqueJoin ? apiMonthList[key].boutiqueJoin : 0} / ${apiMonthList[key].boutiqueTeam ? apiMonthList[key].boutiqueTeam : 0}`,
          start: key,
          end: key,
          changed: `${apiMonthList[key].boutiqueEditTeam>0 ? true : false}`
        });
      }
      if(Number(apiMonthList[key].cafeTeam)>0){
        events.push({
          classtype:'c',
          title: `CAFÉ DIOR ${apiMonthList[key].cafeJoin ? apiMonthList[key].cafeJoin : 0} / ${apiMonthList[key].cafeTeam ? apiMonthList[key].cafeTeam : 0}`,
          start: key,
          end: key,
          changed: `${apiMonthList[key].cafeEditTeam>0 ? true : false}`
        });
      }
      if(Number(apiMonthList[key].docentTeam)>0){
        events.push({
          classtype:'d',
          title: `도슨트 프로그램 ${apiMonthList[key].docentJoin ? apiMonthList[key].docentJoin : 0} / ${apiMonthList[key].docentTeam ? apiMonthList[key].docentTeam : 0} `,
          start: key,
          end: key,
          changed: `${apiMonthList[key].docentEditTeam>0 ? true : false}`
        });
      }
      if(Number(apiMonthList[key].ladyDiorTeam)>0){
        events.push({
          classtype:'l',
          title: `LADY DIOR CELEBRATION ${apiMonthList[key].ladyDiorJoin ? apiMonthList[key].ladyDiorJoin : 0} / ${apiMonthList[key].ladyDiorTeam ? apiMonthList[key].ladyDiorTeam : 0} `,
          start: key,
          end: key,
          changed: `${apiMonthList[key].ladyDiorEditTeam>0 ? true : false}`
        });
      }

    };
    setMonthData(events);
  };
  const _dayData =()=> { // 일간 데이터
    let events = [];
    for (var key in apiDayList) {
      let tempObj = {}
      if(apiDayList[key].boutiqueTeam){
        tempObj = {}
        tempObj.classtype = "db"
       tempObj.title = `부띠크 방문 ${(apiDayList[key].boutiqueJoin)?apiDayList[key].boutiqueJoin:0} / ${apiDayList[key].boutiqueTeam}${(apiDayList[key].changeTypeB)? "(변경)" : ""}` 
      tempObj.start = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
      tempObj.end = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
      tempObj.changed = apiDayList[key].boutiqueEditBool.toString()
        events.push(tempObj)
      }
      
      if(apiDayList[key].cafeTeam){
        tempObj = {}
        tempObj.classtype = "dc"
        tempObj.title = `카페 방문 ${(apiDayList[key].cafeJoin)?apiDayList[key].cafeJoin:0} / ${apiDayList[key].cafeTeam}${(apiDayList[key].changeTypeC)? "(변경)" : ""}`
        tempObj.start = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
        tempObj.end = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
        tempObj.changed = apiDayList[key].cafeEditBool.toString()
        events.push(tempObj)
      }
      if(apiDayList[key].docentTeam){
        tempObj = {}
        tempObj.classtype = "dd"
        tempObj.title = `도슨트 방문 ${(apiDayList[key].docentJoin)?apiDayList[key].docentJoin:0} / ${apiDayList[key].docentTeam}${(apiDayList[key].changeTypeD)? "(변경)" : ""}`
        tempObj.start = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
        tempObj.end = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
        tempObj.changed = apiDayList[key].docentEditBool.toString()
        events.push(tempObj)
      }
      if(apiDayList[key].ladyDiorTeam){
        tempObj = {}
        tempObj.classtype = "dl"
        tempObj.title = `LADY DIOR CELEBRATION ${(apiDayList[key].ladyDiorJoin)?apiDayList[key].ladyDiorJoin:0} / ${apiDayList[key].ladyDiorTeam}${(apiDayList[key].changeTypeL)? "(변경)" : ""}`
        //30분단위추가
        tempObj.start = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
        tempObj.end = new Date(loadYear, loadMonth-1, loadDay, Number(parseInt(apiDayList[key].time/100)), Number(parseInt(apiDayList[key].time%100)))
        tempObj.changed = apiDayList[key].ladyDiorEditBool.toString()
        events.push(tempObj)
      }

    };
    setDayData(events);
  };
 
  const onView = (view) => setEventsCalendarType(view);

  useEffect(()=>{ 
    if(isApiCall) return;
    if(isInit){
      setIsInit(false)
      return;
    }
    if(eventsCalendarType === 'month'){
      MonthListApiCall()
    }else{
      DayListApiCall()
    }
  },[eventsCalendarType]);
  useEffect(()=>{ if(eventsCalendarType === 'month') _monthData(); },[apiMonthList]);
  useEffect(()=>{ 
    if(eventsCalendarType === 'day') _dayData();
  },[apiDayList]);
  const checkBlock =()=>{
    const current = document.querySelectorAll('.rbc-row-segment')
    for(let i=0;i<current.length;i++){
      let ct = current[i].querySelector(".rbc-event")
      if(ct){
        const isRedDot = ct.querySelector(".red_dot")
        if(ct.style.maxWidth === '1004px' && !isRedDot){
          let newDiv = document.createElement("div");
          newDiv.className='red_dot'
          ct.appendChild(newDiv);
        }
      }
    }
    const currentDay = document.querySelectorAll('.rbc-events-container .rbc-event')
    for(let i=0;i<currentDay.length;i++){
      let ct = currentDay[i]
      if(ct){
        const left = Math.floor(ct.style.left.replace('%',''));
        ct.style.left=0;
        ct.style.maxWidth="20%";
        ct.style.padding="5px";
        // console.log(ct.style.maxWidth=0);

        if(ct.title.includes("부띠크 방문") == true) {
          ct.style.left="0%";
        } else if(ct.title.includes("도슨트") == true){
          ct.style.left="20%";
        } else if(ct.title.includes("카페") == true) {
          ct.style.left="40%";
        } else if(ct.title.includes("LADY DIOR CELEBRATION") == true) {
          ct.style.left="60%";
        }
        // if(ct.title)
        // if(left === 50 || left === 33){ 
        //   ct.style.left = '22%'
        // }else if(left === 66){
        //   ct.style.left = '44%'
        // }
        const isRedDot = ct.querySelector(".red_dot")
        if(ct.style.maxWidth === '1004px' && !isRedDot){
          //ct.classList.add('red_dot')
          let newDiv = document.createElement("div");
          newDiv.className='red_dot'
          ct.appendChild(newDiv);
        }
      }
    }
  }
  useEffect(()=>{
    checkBlock()
  },[monthData,dayData]);

  if (error) return <div>에러가 발생했습니다</div>;

  return (
    <>
      <div>
        <Calendar
          localizer={localizer}
          style={{ height: 2000 }}
          views={['month', 'day']}
          components={{toolbar: CustomToolbar}}
          events={eventsCalendarType === "month" ? monthData : dayData}
          scrollToTime={30}
          step={30}
          startAccessor="start"
          endAccessor="end"
          showAllEvents="true"
          eventPropGetter={(event) => {
            if(event.changed === 'true'){
              return ({
                style: {
                  backgroundColor:
                    (event.title.includes("도슨트 프로그램")||event.title.includes("도슨트 방문")) ? "rgba(55,125,255,.1)"
                      : event.title.includes("부띠크 방문") ? "#fdf3e8" 
                      : event.title.includes("LADY DIOR CELEBRATION") ? "#ffcccc"
                      : "#fdeef2",
                  color: "#000",
                  maxWidth:'1004px'
                },
                
              })
            }else{
              return ({
                style: {
                  backgroundColor:
                      (event.title.includes("도슨트 프로그램")||event.title.includes("도슨트 방문")) ? "rgba(55,125,255,.1)"
                      : event.title.includes("부띠크 방문") ? "#fdf3e8"
                      : event.title.includes("LADY DIOR CELEBRATION") ? "#ffcccc"
                      : "#fdeef2",
                  color: "#000",
                },
              })
            }
            
          }
          }
          onSelectEvent={(e) => {
            const _date = dayjs(e.start);

            const _selectTime = {
              classtype: e.classtype,
              start_ymd: _date.format("YYYY-MM-DD"),
              time: _date.get("hour").toString().padStart(2,"0")+""+_date.get("minute").toString().padStart(2,"0"),
              title: e.title,
            };
            setSelectTime(_selectTime);
          }}
          onView={onView}
        />
      </div>
    </>
  )
}

export default Calendars;