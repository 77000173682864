import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

// style
import Style from './index.module.scss';
import Icon from '@mdi/react';
import { mdiCircleSmall } from '@mdi/js';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';
import { dateValue } from 'store/reducers/ReserveCalendar/CalendarDateReducers';

// mui
import { InputNumber, Row, Col, Calendar, Button, Form, Space, TimePicker, Checkbox } from 'antd';
import { PlusCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const CurriculumManagementForm =({ DataLoadFun, DataLoadValue, DataSave })=> {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.CalendarDateReducers);
  const [calenderYYValue, setCalenderYYValue] = useState();
  const [calenderMMValue, setCalenderMMValue] = useState();
  const [calenderDDValue, setCalenderDDValue] = useState();
  const [selectTime, setSelectTime] = useState();
  const [activeButtonIndex, setActiveButtonIndex] = useState(null);
  const [timeCheck, setTimeCheck] = useState();
  const [timeList, setTimeList] = useState([]);
  const [form] = Form.useForm();

  const onChangeCalendar = (value) => {
    setCalenderYYValue(value.format('YYYY'))
    setCalenderMMValue(value.format('MM'))
    setCalenderDDValue(value.format('DD'));
    setSelectTime('')
    setActiveButtonIndex(null)
    form.setFieldsValue({
      TeamNumber: '',
      PersonnelNumber: '',
      ExcludingWeekends: false,
    });
  }

  const onChangeTimePicker = (date, dateString) => setTimeCheck(dateString);

  const handleAdd =()=> {
    if(timeCheck){
      ReservationTeamCheck(timeCheck);
    };
  };

  const ReservationTeamCheck = (value) => {
    if (!timeList.includes(value)) {
      let timedate = {}
      timedate.time = value
      timedate.reserveBool = true
      timedate.editable = true
      setTimeList([...timeList, timedate]);
    }
  };

  const handleButtonClick = (index) => {
    setActiveButtonIndex(index);
    activeTimeValue(index);
  };

  const activeTimeValue =(index)=> {
    let _time = parseInt(index.split(':')[0] + index.split(':')[1]);
    setSelectTime(_time)
    if(DataLoadValue[_time]){

      form.setFieldsValue({
        TeamNumber: DataLoadValue[_time].editteam ? DataLoadValue[_time].editteam : DataLoadValue[_time].team,
        PersonnelNumber: DataLoadValue[_time].editperson ? DataLoadValue[_time].editperson : DataLoadValue[_time].person,
        ExcludingWeekends: DataLoadValue[_time].allow_reserve,
      })
    }else{
      form.setFieldsValue({
        TeamNumber: '',
        PersonnelNumber: '',
        ExcludingWeekends: false,
      });
    };
  };

  const _ReservationTimeList =()=> {
    const _date = dayjs(count.date.year + '-' + count.date.month + '-' + count.date.day);

    if(count.date.year){
      form.setFieldsValue({
        CalendarDayChoice: _date,
      });
    }else{
      form.setFieldsValue({
        CalendarDayChoice: dayjs(),
      });
    };
  }

  const ButtonCancel =()=> dispatch(ModalsValue({type:2, show:true, text:"Alert_05", okfunc:"Alert_05"}));

  const onFinish = (values) => {
    DataSave(values, calenderYYValue,calenderMMValue,calenderDDValue, selectTime)
  }

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
  };

  useEffect(()=>{
    if(!calenderYYValue || !calenderMMValue || !calenderDDValue) return;
    // console.log('calenderYYValue,calenderMMValue,calenderDDValue', calenderYYValue,calenderMMValue,calenderDDValue)
    dispatch(dateValue({year:calenderYYValue, month:calenderMMValue, day:calenderDDValue}));
  },[calenderYYValue,calenderMMValue,calenderDDValue]);
  useEffect(()=>{
    // console.log(count)
    const _date = dayjs(count.date.year + '-' + count.date.month + '-' + count.date.day);

    if(count.date.year){
      form.setFieldsValue({
        CalendarDayChoice: _date,
      });
    }
    setCalenderYYValue(count.year)
    setCalenderMMValue(count.month)
    setCalenderDDValue(count.day);
    // DataLoadFun()
  },[count]);



  useEffect(()=>{
    let _times =[];
    for (var key in DataLoadValue) {
      let timedate = {}
      timedate.time = parseInt(key/100).toString().padStart(2,"0") + ":" + parseInt(key%100).toString().padStart(2,"0");
      timedate.reserveBool = DataLoadValue[key].allow_reserve
      timedate.editable = DataLoadValue[key].editable
      _times.push(timedate)
    };

    setTimeList(_times);
  },[DataLoadValue])

  useEffect(()=>{
    _ReservationTimeList()
  },[]);

  return (
    <>
      <Form
        form={form}
        className={Style['ReservationTimeList']}
        name="ReservationTimeList"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row>
          <Col span={10}>
            <Form.Item name="CalendarDayChoice">
              <Calendar 
                className={Style['Calendar']}
                fullscreen={false}
                onChange={onChangeCalendar}
                
                headerRender={({ value }) => {
                  const year = value.year();
                  const month = value.month()+1;
                  return (
                    <div className={Style['headerRender']}>{year}년 {month}월</div>
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 6, offset: 1 }}>
            <div className={Style['TimeTitle']}>예약 시간 리스트 <ClockCircleOutlined /></div>
            <div className={Style['box']}>
                <TimePicker className={Style['TimePicker']} onChange={onChangeTimePicker} minuteStep={30} picker="time" format="HH:mm" />&nbsp;&nbsp;
                <PlusCircleOutlined onClick={handleAdd} />

              <div className={Style['TimeList']}>
                {timeList.map((item) => {
                  if(item.editable){
                    if(item.reserveBool){
                      return(
                        <Button key={item.time} onClick={() => handleButtonClick(item.time)} type={activeButtonIndex === item.time ? 'primary' : 'default'}>
                          {item.time}<Icon className={Style['Icon']} path={mdiCircleSmall} size={2} />
                        </Button>
                      )
                    }else{
                      return(
                        <Button className={Style['disabled']} key={item.time} onClick={() => handleButtonClick(item.time)} type={activeButtonIndex === item.time ? 'primary' : 'default'}>
                          {item.time}<Icon className={Style['Icon']} path={mdiCircleSmall} size={2} />
                        </Button>
                      )
                    }
                  }else{
                    if(item.reserveBool){
                      return(
                        <Button key={item.time} onClick={() => handleButtonClick(item.time)} type={activeButtonIndex === item.time ? 'primary' : 'default'}>
                          {item.time}
                        </Button>
                      )
                    }else{
                      return(
                        <Button className={Style['disabled']} key={item.time} onClick={() => handleButtonClick(item.time)} type={activeButtonIndex === item.time ? 'primary' : 'default'}>
                          {item.time}
                        </Button>
                      )
                    }
                  }
                })}
                {/* <Button key={'11:00'} className={Style['disabled']} onClick={() => handleButtonClick(1)} type={activeButtonIndex === 1 ? 'primary' : 'default'}>
                  11:00 <Icon className={Style['Icon']} path={mdiCircleSmall} size={2} />
                </Button>
                <Button key={'11:00'} disabled>11:00 <Icon className={Style['Icon']} path={mdiCircleSmall} size={2} /></Button>
                <Button key={'12:00'} onClick={() => handleButtonClick(0)} type={activeButtonIndex === 0 ? 'primary' : 'default'}>
                  12:00 <Icon className={Style['Icon']} path={mdiCircleSmall} size={2} />
                </Button> */}
              </div>
            </div>
          </Col>
          <Col xs={{ span: 6, offset: 1 }}>
            <div className={Style['box']}>
              <Form.Item labelCol={{ span: 24 }} label="예약 가능한 팀 수" name="TeamNumber" rules={[{ required: true, message: '필수 입력 입니다.' }]}>
                <InputNumber min={0} className={Style['InputNumber']} />
              </Form.Item>
              <Form.Item labelCol={{ span: 24 }} label="팀당 가능한 인원 수" name="PersonnelNumber" rules={[{ required: true, message: '필수 입력 입니다.' }]}>
                <InputNumber min={0} className={Style['InputNumber']} />
              </Form.Item>
              <Form.Item label="예약 가능 여부" name="ExcludingWeekends" valuePropName="checked">
                <Checkbox />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row className={Style['btnBox']}>
          <Col span={24} align="right">
            <Form.Item>
              <Space>
                <Button danger onClick={ButtonCancel}>취소</Button>
                <Button type="primary" htmlType="submit">설정하기</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
};

export default CurriculumManagementForm;