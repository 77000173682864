import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { boardDetail, boardEdit, boardRegistration } from 'lib/Api';

// style
import Style from './index.module.scss';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// mui
import { Form, Col, Row, Input, Checkbox, Button, Space } from 'antd';
const { TextArea } = Input;

const View = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [koTitle, setKoTitle] = useState();
  const [koContents, setKoContents] = useState();
  const [enTitle, setEnTitle] = useState();
  const [enContents, setEnContents] = useState();
  const [visibleState, setVisibleState] = useState('N');

  const noticeDetailLoad = async () => {
    try {
      setError(null); // error 초기화
      setLoading(true); // loading 상태 변경
      const passData = {'idx': id}
      const _data = await boardDetail(passData);
      if(_data.data.statusCode === 200){
        setKoTitle(_data.data.boardDetail.title);
        setKoContents(_data.data.boardDetail.contents);
        setEnTitle(_data.data.boardDetail.enTitle);
        setEnContents(_data.data.boardDetail.enContents);
        setVisibleState(_data.data.boardDetail.visibleState);
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
    }catch(e){
      setError(e);
    }
    setLoading(false);
  };

  const noticeEdit = async () => {
    try {
      let formData = {};
      formData.title = koTitle;
      formData.contents = koContents;
      formData.enTitle = enTitle;
      formData.enContents = enContents;
      formData.visibleState = visibleState;
      formData.idx = id;
      const _data = await boardEdit(formData);
      setLoading(false);
      if(_data.data.statusCode === 200){
        dispatch(ModalsValue({type:1, show:true, text:"Alert_06", okfunc:"Alert_06"}));
        navigate("/Notice");
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
    }catch(e){
      setError(e);
    }
  };

  const noticeWrite = async () => {
    try {
      let formData = {};
      formData.title = koTitle;
      formData.contents = koContents;
      formData.enTitle = enTitle;
      formData.enContents = enContents;
      formData.visibleState = visibleState;
      const _data = await boardRegistration(formData);
      setLoading(false);
      if(_data.data.statusCode === 200){
        dispatch(ModalsValue({type:1, show:true, text:"Alert_10", okfunc:"Alert_10"}));
        //navigate("/Notice/View/"+_data.data.returnIdx);
        navigate("/Notice");
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
    }catch(e){
      setError(e);
    }
  };

  const onChange =(e)=> {
    if(e.target.id === 'NoticeFrom_titleKo'){
      setKoTitle(e.target.value);
    }else if(e.target.id === 'NoticeFrom_textKo'){
      setKoContents(e.target.value);
    }else if(e.target.id === 'NoticeFrom_titleEn'){
      setEnTitle(e.target.value);
    }else if(e.target.id === 'NoticeFrom_textEn'){
      setEnContents(e.target.value);
    }else if(e.target.id === 'NoticeFrom_show'){
      if(e.target.checked === true){
        setVisibleState('Y');
      }else{
        setVisibleState('N');
      }
    }
  };

  const onFinish =()=> {
    if(id === "Add"){
      noticeWrite();
    }else{
      noticeEdit();
    };
  };

  const ButtonCancel =()=> {
    dispatch(ModalsValue({type:2, show:true, text:"Alert_05", okfunc:"Alert_05"}));
  };

  useEffect(()=>{
    if(id === "Add") return;
    noticeDetailLoad();
  },[]);

  if (loading) return <div>로딩중..</div>; 
  if (error) return <div>에러가 발생했습니다</div>;

  return (
    <>
      <Helmet>
        <title>{props.metaTitle}</title>
      </Helmet>
      <h2 className='contentsTitle'>{props.metaTitle}</h2>

      <Form
        form={form}
        name="NoticeFrom"
        className={Style['FromWrap']}
        labelCol={{ flex: '110px' }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        onFinish={onFinish}
        fields={[
          {name: ["titleKo"],value: koTitle},
          {name: ["textKo"],value: koContents},
          {name: ["titleEn"],value: enTitle},
          {name: ["textEn"],value: enContents}
        ]}
      >
        <Row className={Style['firstAntCol']}>
          <Col span={24}>
            <Form.Item label="제목(국문)" name="titleKo" rules={[{ required: !koTitle, message: '필수 입력 입니다.' }]}>
              <Input allowClear onChange={onChange} />
            </Form.Item>
            <Form.Item label="내용(국문)" name="textKo" rules={[{ required: !koContents, message: '필수 입력 입니다.' }]}>
              <TextArea rows={6} onChange={onChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="제목(영문)" name="titleEn" rules={[{ required: !enTitle, message: '필수 입력 입니다.' }]}>
              <Input allowClear onChange={onChange} />
            </Form.Item>
            <Form.Item label="내용(영문)" name="textEn" rules={[{ required: !enContents, message: '필수 입력 입니다.' }]}>
              <TextArea rows={6} onChange={onChange} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="노출여부" name="show">
              <Checkbox checked={visibleState === 'Y' ? (true):(false)} onChange={onChange}></Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item align="right">
          <Row>
            <Col span={24} align="right">
              <Space>
                <Button danger onClick={ButtonCancel}>취소</Button>
                <Button 
                  type="primary" htmlType="submit"
                  disabled={!koTitle || !koContents || !enTitle || !enContents}
                >등록하기</Button>
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  )
}

export default View;