import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

// project import
import Header from "layouts/AdminLayout/Header";
import Side from "layouts/AdminLayout/Side";

// mui
import { Layout, theme } from 'antd';
const { Content } = Layout;

const AdminLayout =()=> {
  const [collapsed, setCollapsed] = useState(false);
  const {token: { colorBgContainer }} = theme.useToken();

  return(
    <>
      <Layout className='AdminLayout' hasSider>
        <Side collapsed={collapsed} />
        
        <Layout className="site-layout">
          <Header collapsed={collapsed} setCollapsed={setCollapsed} colorBgContainer={colorBgContainer} />

          <Content className='contents' style={{background:colorBgContainer}}>
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default AdminLayout;