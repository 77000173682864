import React from 'react';
import { useNavigate } from "react-router-dom";

// mui
import Icon from '@mdi/react';
import { mdiArrowExpandLeft, mdiArrowExpandRight, mdiLogoutVariant  } from '@mdi/js';
import { Layout, Button } from 'antd';
const { Header } = Layout;

const Heade =({ collapsed, setCollapsed, colorBgContainer })=> {
  let navigate = useNavigate();

  const logout = () => {
    sessionStorage.removeItem('token');
    navigate("/Login");
  };

  return(
    <>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <span
          className="trigger"
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <Icon path={mdiArrowExpandRight} size='0.875rem' /> : <Icon path={mdiArrowExpandLeft} size='0.875rem' />}
        </span>
        <h1>DIOR CMS</h1>

        <Button 
          type="link"
          className='btnLogout'
          icon={<Icon path={mdiLogoutVariant} size='0.875rem' style={{ marginRight:"0.25rem" }} />} 
          onClick={(e) => logout()}
        >
          로그아웃
        </Button>
      </Header>
    </>
  );
};

export default Heade;