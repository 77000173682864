import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_AES_SECRETKEY

//암호화
export const encrypt = (val) => {
    let text = val.toString();
    const data = {
        value:text
    };
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();    
    return encodeURIComponent(encrypted)
} 


//복호화
export const decrypt = (encrypted) => {
    try {
        const bytes = CryptoJS.AES.decrypt(decodeURIComponent(encrypted), secretKey);
        const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decrypted;

    }catch(err) {
        console.log(`[Decrypt Err]: ${err}`);
    }
}
