import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { reservationList } from "lib/Api";
import { useNavigate } from "react-router-dom";
// import axios from 'axios';
import moment from "moment";
import dayjs from "dayjs";
import { addToZero } from "lib/common";

// style
import Style from "./index.module.scss";

// project import
import TableList from "components/items/TableList";
import Pagination from "components/items/Pagination";

// mui
import { InputLabel } from "@mui/material";
import { Col, Row, Select, Input, Button, DatePicker } from "antd";
import Icon from "@mdi/react";
import { mdiConsoleNetwork, mdiMicrosoftExcel } from "@mdi/js";

let isFirstLoad = true;
const { RangePicker } = DatePicker;

const dataTitle = [
	"No",
	"이름",
	"연락처",
	"예약종류",
	"방문 일",
	"방문 시간",
	"방문인원",
	"예약 일시",
	"취소 일시",
	"예약 상태",
	"입장 상태",
];
const dateFormat = "YYYY-MM-DD HH:mm";

const todayStr = moment().format("YYYY-MM-DD");
const searchStart = moment(`${todayStr} 00:00`)
	.add("2", "d")
	.format(dateFormat);
const searchEnd = moment(`${todayStr} 23:59`).add("2", "d").format(dateFormat);
const searchReserveStart = dayjs(new Date())
	.subtract(3, "M")
	.format(dateFormat);
const searchReserveEnd = dayjs(new Date()).format(dateFormat);

const ReserveList = (props) => {
	const navigate = useNavigate();

	const [boardList, setBoardList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [eLoading, setELoading] = useState(false);
	const [error, setError] = useState(null);
	const [revStartDate, setRevStartDate] = useState(null);
	const [revEndDate, setRevEndDate] = useState(null);
	const [searchDays, setSearchDays] = useState(""); // 방문 일시

	const limit = 10;
	const [page, setPage] = useState(1);
	const offset = (page - 1) * limit;

	const reserveListLoad = async () => {
		try {
			setError(null); // error 초기화
			setLoading(true); // loading 상태 변경
			const _data = await reservationList();

			if (_data.data.statusCode === 200) {
				if (isFirstLoad) {
					setSearchDays([searchStart, searchEnd]);
				}
				setBoardList(_data.data.reservationUsers); // searchDays 기준으로 재정렬
				// drawSearchResult();
			} else {
				alert(_data.data.error);
				navigate("/Login");
			}
		} catch (e) {
			setError(e);
		}
		setLoading(false);
	};

	const excelDownload = () => {
		if (eLoading) return;
		setELoading(true);
		// console.log(searchReservationType,searchReservationState,searchDays,searchReservationDate,searchName,searchContact)
		let checkURL =
			process.env.REACT_APP_BASEURL + "cms/reservation/listDown?";
		if (searchReservationType) {
			checkURL += `viewType=${searchReservationType}`;
		}
		if (searchReservationState) {
			checkURL += `&reservationState=${searchReservationState}`;
		}
        if (searchReservationCheckin) {
			checkURL += `&checkin=${searchReservationCheckin.replaceAll(" ", "")}`;
		}
		if (searchDays[0]) {
			checkURL += `&startVisitDate=${searchDays[0]}&endVisitDate=${searchDays[1]}`;
		} else {
			checkURL += `&startVisitDate=2020-01-01%2000:00&endVisitDate=2030-12-31%2023:59`;
		}

		if (searchReservationDate[0]) {
			checkURL += `&startResevetionDate=${searchReservationDate[0]}&endResevetionDate=${searchReservationDate[1]}`;
		} else {
			checkURL += `&startResevetionDate=2020-01-01%2000:00&endResevetionDate=2030-12-31%2023:59`;
		}
		if (searchName) {
			checkURL += `&userName=${searchName}`;
		}
		if (searchContact) {
			checkURL += `&userContact=${searchContact}`;
		}
		// console.log(checkURL)

		fetch(checkURL.toString())
			.then((response) => {
				response.blob().then((blob) => {
					const nd = new Date();
					const ndDate =
						nd.getFullYear() +
						addToZero(nd.getMonth() + 1, "00") +
						addToZero(nd.getDate(), "00");
					let url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");
					a.href = url;
					a.download = "디올성수 예약리스트_" + ndDate + ".xlsx";
					a.click();
					setELoading(false);
				});
			})
			.catch((error) => {
				setELoading(false);
			});
	};

	const [searchReservationType, setSearchReservationType] = useState("All");
	const [searchReservationState, setSearchReservationState] = useState("All");
    const [searchReservationCheckin, setSearchReservationCheckin] = useState("All");
	// const [searchDays, setSearchDays] = useState('');
	const [isReserveDateAll, setIsReserveDateAll] = useState(true);
	const [searchReservationDate, setSearchReservationDate] = useState([]);
	const [searchName, setSearchName] = useState("");
	const [searchContact, setSearchContact] = useState("");
	const [searchValue, setSearchValue] = useState();

	const reservationTypeChange = (e) => {
		setSearchReservationType(e);
	};
	const reservationStateChange = (e) => {
		setSearchReservationState(e);
	};
    const reservationCheckinChange = (e) => {
		setSearchReservationCheckin(e);
	};

	const visitDateChange = (date, dateStrings) => {
		const startDay = moment(dateStrings[0].split(" ")[0], "YYYY-MM-DD");
		const endDay = moment(dateStrings[1].split(" ")[0], "YYYY-MM-DD");
		const startTime = moment(dateStrings[0].split(" ")[1], "HH:mm");
		const endDayTime = moment(dateStrings[1].split(" ")[1], "HH:mm");
		let result = [
			startDay._i + " " + startTime._i,
			endDay._i + " " + endDayTime._i,
		];
		if (!startDay._i) result = [];
		setSearchDays(result);
	};

	const reservationDateChange = (date, dateStrings) => {
		const startDay = moment(dateStrings[0].split(" ")[0], "YYYY-MM-DD");
		const endDay = moment(dateStrings[1].split(" ")[0], "YYYY-MM-DD");
		const startTime = moment(dateStrings[0].split(" ")[1], "HH:mm");
		const endDayTime = moment(dateStrings[1].split(" ")[1], "HH:mm");
		let result = [
			startDay._i + " " + startTime._i,
			endDay._i + " " + endDayTime._i,
		]; // [startDay,startTime,endDay,endDayTime];
		if (!startDay._i) result = [];
		setSearchReservationDate(result);
		setIsReserveDateAll(false);
	};

	const nameChange = (e) => {
		setSearchName(e.target.value);
	};
	const contactChange = (e) => {
		setSearchContact(e.target.value);
	};

	const buttonSearch = () => {
		//버튼이눌리면 새 데이터 로드
		isFirstLoad = false;
		reserveListLoad();
	};
	const drawSearchResult = () => {
		const allFilter = boardList.filter((data) => {
			let isFilter = false;
			if (
				searchReservationType === "All" ||
				data.reservationType
					.toLowerCase()
					.includes(searchReservationType.toLowerCase())
			) {
				isFilter = true;
			} else {
				return "";
			}

			if (
				searchReservationState === "All" ||
				data.reservationState
					.toLowerCase()
					.includes(searchReservationState.toLowerCase())
			) {
				isFilter = true;
			} else {
				return "";
			}

            if (
				searchReservationCheckin === "All" ||
				data.isCheckin
					.toLowerCase()
					.includes(searchReservationCheckin.toLowerCase())
			) {
				isFilter = true;
			} else {
				return "";
			}


			if (data.name.toLowerCase().includes(searchName.toLowerCase())) {
				isFilter = true;
			} else {
				return "";
			}
			if (
				data.contact.toLowerCase().includes(searchContact.toLowerCase())
			) {
				isFilter = true;
			} else {
				return "";
			}

			if (!isReserveDateAll) {
				const startDate = new Date(searchReservationDate[0]);
				const endDate = new Date(searchReservationDate[1]);
				const reservationDate = new Date(data.reservationDate);
				if (
					(startDate < reservationDate &&
						reservationDate < endDate) ||
					searchReservationDate.length === 0
				) {
					isFilter = true;
				} else {
					return "";
				}
			}

			const visitStartDate = new Date(searchDays[0]);
			const visitEndDate = new Date(searchDays[1]);
			const visitDate = new Date(
				data.visitDate + " " + data.visitTime + ":00"
			);

			if (
				(visitStartDate < visitDate && visitDate < visitEndDate) ||
				searchDays.length === 0
			) {
				isFilter = true;
			} else {
				return "";
			}

			if (isFilter) {
				return data;
			} else {
				return "";
			}
		});

		setSearchValue(allFilter);
	};

	useEffect(() => {
		drawSearchResult();
	}, [boardList]);

	useEffect(() => {
		isFirstLoad = true;
		reserveListLoad();
		setRevStartDate(dayjs(new Date()).subtract(1, "M").format(dateFormat));
		setRevEndDate(dayjs(new Date()).format(dateFormat));
		// searchReservationDate[0] = searchReserveStart;
		// searchReservationDate[1] = searchReserveEnd;
		// console.log(dayjs(new Date()).subtract(3, "M").format(dateFormat));
	}, []);

	if (isFirstLoad && loading) return <div>로딩중..</div>;
	if (error) return <div>에러가 발생했습니다</div>;
	if (!boardList) return null;

	return (
		<>
			<Helmet>
				<title>{props.metaTitle}</title>
			</Helmet>
			<h2 className="contentsTitle">{props.metaTitle}</h2>

			<div className={Style["searchFrom"]}>
				<Row align="bottom" gutter={[0, 20]}>
					<Col span={6}>
						<InputLabel>예약 종류</InputLabel>
						<Select
							className={Style["Select"]}
							defaultValue="전체"
							onChange={reservationTypeChange}
							options={[
								{
									value: "All",
									label: "전체",
								},
								{
									value: "docent",
									label: "도슨트 프로그램",
								},
								{
									value: "boutique",
									label: "부띠크 방문",
								},
								{
									value: "cafe",
									label: "CAFÉ DIOR",
								},
							]}
						/>
					</Col>
					<Col xs={{ span: 6, offset: 1 }}>
						<InputLabel>예약 상태</InputLabel>
						<Select
							className={Style["Select"]}
							defaultValue="전체"
							onChange={reservationStateChange}
							options={[
								{
									value: "All",
									label: "전체",
								},
								{
									value: "Y",
									label: "예약",
								},
								{
									value: "N",
									label: "취소",
								},
							]}
						/>
					</Col>
					<Col xs={{ span: 6, offset: 1 }}>
						<InputLabel>입장 상태</InputLabel>
						<Select
							className={Style["Select"]}
							defaultValue="전체"
							onChange={reservationCheckinChange}
							options={[
								{
									value: "All",
									label: "전체",
								},
								{
									value: "입장 전",
									label: "입장전",
								},
								{
									value: "입장완료",
									label: "입장완료",
								},
								{
									value: "노쇼",
									label: "노쇼",
								},
							]}
						/>
					</Col>
				</Row>
				<Row align="bottom" gutter={[0, 20]}>
					<Col span={10}>
						<InputLabel>방문 일시</InputLabel>
						<RangePicker
							defaultValue={[
								dayjs(searchStart, dateFormat),
								dayjs(searchEnd, dateFormat),
							]}
							className={Style["RangePicker"]}
							onChange={visitDateChange}
							showTime
							use12Hours={false}
							format="YYYY-MM-DD HH:mm"
							minuteStep={60}
						/>
					</Col>
					<Col xs={{ span: 10, offset: 1 }}>
						<InputLabel>예약 일시</InputLabel>
						<RangePicker
							className={Style["RangePicker"]}
							onChange={reservationDateChange}
							showTime
							use12Hours={false}
							format="YYYY-MM-DD HH:mm"
							minuteStep={60}
							placement="bottomRight"
						/>
					</Col>
					<Col span={10}>
						<InputLabel>이름</InputLabel>
						<Input allowClear onChange={nameChange} />
					</Col>
					<Col xs={{ span: 10, offset: 1 }}>
						<InputLabel>연락처</InputLabel>
						<Input allowClear onChange={contactChange} />
					</Col>
					<Col xs={{ span: 2, offset: 1 }}>
						<Button onClick={buttonSearch}>검색</Button>
					</Col>
				</Row>
			</div>

			<Row className={Style["ExcelDownloadRow"]}>
				<Col span={24} align="right">
					<Button
						icon={<Icon path={mdiMicrosoftExcel} size="0.975rem" />}
						onClick={excelDownload}
					>
						Excel Download
					</Button>
				</Col>
			</Row>

			<TableList
				listName="ReserveList"
				dataTitle={dataTitle}
				data={searchValue ? searchValue : boardList}
				offset={offset}
				limit={limit}
			/>

			<Pagination
				total={searchValue ? searchValue.length : boardList.length}
				limit={limit}
				setPage={setPage}
			/>
		</>
	);
};

export default ReserveList;
