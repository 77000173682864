import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// project import
import OpenSettingsPasswordModals from 'components/ReserveCalendar/OpenSettingsPasswordModals';
import CurriculumReservationListModals from 'components/ReserveCalendar/CurriculumReservationListModals';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// material-ui
import { Modal } from 'antd';

const BasicsModals = ({ setOpen, open }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.ModalsReducers.ModalsValue.text);
  // console.log(count)

  const onCancel =(e)=> {
    dispatch(ModalsValue({show:false}));
    setOpen(false);
  }

  return (
    <>
        {count === 'OpenSettingsPasswordModals' ? (
          <Modal
            className='basicsModals'
            open={open}
            maskClosable={false}
            onCancel={onCancel}
            centered={true}
            footer={null}
          >
            <OpenSettingsPasswordModals />
          </Modal>
        ) : count === 'CurriculumReservationListModals' ? (
          <Modal
            className='basicsModals'
            open={open}
            maskClosable={false}
            onCancel={onCancel}
            centered={true}
            closable={false}
            footer={null}
            width={800}
          >
            <CurriculumReservationListModals />
          </Modal>
        ) : count === 'test' ? (
          <>test</>
        ) : (
          <>null</>
        )}
    </>
  )
}

export default BasicsModals;