import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { textBoardDetail, textBoardRegistration } from 'lib/Api';

// style
import Style from './index.module.scss';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// mui
import { Form, Input, Button, Space, Row, Col } from 'antd';
const { TextArea } = Input;

const View = (props) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [etcTitle, setEtcTitle] = useState('');
  const [koTitle, setKoTitle] = useState('');
  const [koContents, setKoContents] = useState('');
  const [enTitle, setEnTitle] = useState('');
  const [enContents, setEnContents] = useState('');

  const textdetailLoad = async () => {
    try {
      setError(null); // error 초기화
      setLoading(true); // loading 상태 변경
      const passData = {'idx': id}
      const _data = await textBoardDetail(passData);
      if(_data.data.statusCode === 200){
        setEtcTitle(_data.data.boardDetail.textTitle)
        setKoTitle(_data.data.boardDetail.koTitle);
        setKoContents(_data.data.boardDetail.koContents);
        setEnTitle(_data.data.boardDetail.enTitle);
        setEnContents(_data.data.boardDetail.enContents);
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
    }catch(e){
      setError(e);
    }
    setLoading(false);
  };

  const textDataSend = async () => {
    try {
      let formData = {};
      formData.id = id;
      formData.koTitle = koTitle;
      formData.koContents = koContents;
      formData.enTitle = enTitle;
      formData.enContents = enContents;
      // console.log(formData)
      const _data = await textBoardRegistration(formData);
      // console.log(_data);
      if(_data.data.statusCode === 200){
        dispatch(ModalsValue({type:1, show:true, text:"Alert_06", okfunc:"Alert_06"}));
        navigate("/TextManagement");
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
      
    }catch(e){
      setError(e);
    }
    setLoading(false);
  };

  const onChange =(e)=> {
    if(e.target.id === 'TextManagementFrom_TitleKo'){
      setKoTitle(e.target.value);
    }else if(e.target.id === 'TextManagementFrom_TextAreaKo'){
      setKoContents(e.target.value);
    }else if(e.target.id === 'TextManagementFrom_TitleEn'){
      setEnTitle(e.target.value);
    }else if(e.target.id === 'TextManagementFrom_TextAreaEn'){
      setEnContents(e.target.value);
    }
  };

  const onFinish =()=> {
    textDataSend();
    dispatch(ModalsValue({type:1, show:true, text:"Alert_06", okfunc:"Alert_06"}));
  };

  const ButtonCancel =()=> {
    dispatch(ModalsValue({type:2, show:true, text:"Alert_05", okfunc:"Alert_05"}));
  };

  useEffect(()=>{textdetailLoad()},[]);

  if (loading) return <div>로딩중..</div>; 
  if (error) return <div>에러가 발생했습니다</div>;

  return (
    <>
      <Helmet>
        <title>{etcTitle}</title>
      </Helmet>
      <h2 className='contentsTitle'>{props.metaTitle} - {etcTitle}</h2>

      <Form
        form = {form}
        name="TextManagementFrom"
        labelCol={{
          span: 4,
        }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        className={Style['FromWrap']}
        onFinish={onFinish}
        fields={[
          {name: ["TitleKo"],value: koTitle},
          {name: ["TextAreaKo"],value: koContents},
          {name: ["TitleEn"],value: enTitle},
          {name: ["TextAreaEn"],value: enContents}
        ]}
      >
        {etcTitle === "예약 안내 문구" ? (
          <>
            <Form.Item label="타이틀(국문)" name="TitleKo" rules={[{ required: !koTitle, message: '필수 입력 입니다.' }]}>
              <Input onChange={onChange} />
            </Form.Item>
            <Form.Item label="내용(국문)" name="TextAreaKo" rules={[{ required: !koContents, message: '필수 입력 입니다.' }]}>
              <TextArea rows={6} onChange={onChange} />
            </Form.Item>
            <Form.Item label="타이틀(영문)" name="TitleEn" rules={[{ required: !enTitle, message: '필수 입력 입니다.' }]}>
              <Input onChange={onChange} />
            </Form.Item>
            <Form.Item label="내용(영문)" name="TextAreaEn" rules={[{ required: !enContents, message: '필수 입력 입니다.' }]}>
              <TextArea rows={6} onChange={onChange} />
            </Form.Item>
          </>
        ):(
          <>
            <Form.Item label="국문" name="TextAreaKo" rules={[{ required: !koContents, message: '필수 입력 입니다.' }]}>
              <TextArea rows={6} onChange={onChange} />
            </Form.Item>
            <Form.Item label="영문" name="TextAreaEn" rules={[{ required: !enContents, message: '필수 입력 입니다.' }]}>
              <TextArea rows={6} onChange={onChange} />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <Row>
            <Col span={24} align="right">
              <Space>
                <Button danger onClick={ButtonCancel}>취소</Button>
                <Button 
                  type="primary" htmlType="submit" 
                  disabled={
                    etcTitle === "예약 안내 문구" ? (
                      !koTitle || !koContents || !enTitle || !enContents
                    ):(
                      !koContents || !enContents
                    )
                  }
                >수정하기</Button>
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  )
}

export default View;