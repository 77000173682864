import React from 'react';
import LayoutIndex from 'layouts';

// project import
import PageLink from "pages/PageLink";
import Login from "pages/Login";

// ==============================|| AUTH ROUTING ||============================== //
const loginRoutes = {
  path: '/',
  element: <LayoutIndex layout="login" />,
  children: [
    {
      index: true,
      element: <Login metaTitle='로그인' />,
    },
    {
      path: "all",
      element: <PageLink metaTitle='페이지 링크' />,
    },
    {
      path: "Login",
      element: <Login metaTitle='로그인' />,
    }
  ]
};

export default loginRoutes;
