
import React, {useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";

// style
import Style from './PageLink.module.scss';
import { encrypt } from 'lib/crypto';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';
import { adminAdd, managerPassInsert } from 'lib/Api';

// mui
import { Button } from 'antd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const PageLink = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [loading,setLoading] = useState(false)
  const [adminPass,setAdminPass] = useState('')
  const [managerPass,setManagerPass] = useState('')
  const [viewBool,setViewBool] = useState(false)

  const btnOpenSettings = (values) => {
    dispatch(ModalsValue({show:false}));
    navigate('/ReserveCalendar/OpenSettings', {state:{active:"On", date:"2023.12.12 11:11:11"}});
  };
  const TextManagementView = (values) => {
    dispatch(ModalsValue({show:false}));
    navigate('/TextManagement/View/1', {state:{Title:'개인정보처리방침(개인정보 수집 동의)'}});
  };

  const adminPassChange = (e) => {
    const {value} = e.target;
    setAdminPass(value)
  };
  const managerPassChange = (e) => {
    const {value} = e.target;
    setManagerPass(value)
  };
  const adminPassInsert = async () => {
    if(loading) return;
    setLoading(true)
    try {
      if(!adminPass) {
        alert('비밀번호를 입력해 주세요.')
        return;
      }
      let sendData = {'value':encrypt(adminPass)};
      let _data = await adminAdd(sendData)
      setAdminPass('');
      setLoading(false)
      alert('관리자 비밀번호가 등록 되었습니다.')
      // console.log(_data)
    }catch(e){
      setLoading(false)
      console.log(e);
    }
  };
  const openManagerPassInsert = async () => {
    if(loading) return
    setLoading(true)
    try {
      if(!managerPass) {
        alert('비밀번호를 입력해 주세요.')
        return;
      }
      let sendData = {'value':encrypt(managerPass)};
      let _data = await managerPassInsert(sendData)
      setLoading(false)
      setManagerPass('');
      alert('오픈 매니저 비밀번호가 등록 되었습니다.')
      // console.log(_data)
    }catch(e){
      setLoading(false)
      console.log(e);
    }
  };
  const checkIP = async () => {
    const ipData = await fetch('https://geolocation-db.com/json/');
    const locationIp = await ipData.json();
    if('119.192.238.211' !== locationIp.IPv4 && '112.216.68.162' !== locationIp.IPv4 && '121.161.186.102' !== locationIp.IPv4){
      navigate("/");
    }else{
      setViewBool(true)
    }
  }
  useEffect(()=>{
    checkIP();
  },[])
  
  return (
    (viewBool) ? 
      <>
        <div style={{marginLeft:'30px', marginTop:'30px'}}>
          <div>
            <p>관리자 비밀번호 등록</p>
            <input value={adminPass} onChange={adminPassChange} style={{marginRight:'10px'}}/>
            <button onClick={adminPassInsert}>{loading ? '등록중...' : '관리자 등록'}</button>
          </div>
          <br/>
          <div>
            <p>오픈 매니저 비밀번호 등록</p>
            <input value={managerPass} onChange={managerPassChange} style={{marginRight:'10px'}}/>
            <button onClick={openManagerPassInsert}>{loading ? '등록중...' : '오픈 매니저 등록'}</button>
          </div>
        </div>
        <ul className={Style['PageLink']}>
          <li className={Style['PageList']}>
            <p><Link to="/Login">로그인</Link></p>
            <p><Link to="/ReserveCalendar">예약 캘린더</Link></p>
            <p onClick={btnOpenSettings}> - 오픈 설정</p>
            <p> - <Link to="/ReserveCalendar/CurriculumManagement">커리큘럼 관리</Link></p>
            <p> - <Link to="/ReserveCalendar/CurriculumRegistration">커리큘럼 등록하기</Link></p>
            <p><Link to="/ReserveList">예약 리스트</Link></p>
            <p><Link to="/Notice">공지사항</Link></p>
            <p> - <Link to="/Notice/View/1">공지사항 View</Link></p>
            <p><Link to="/TextManagement">텍스트 관리</Link></p>
            <p onClick={TextManagementView}> - 텍스트 관리 View</p>
          </li>

          <li className={Style['PopupList']}>
            <strong>Popup</strong>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_01"}))}>등록되었습니다.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:2, show:true, text:"Alert_02", okfunc:"Alert_02"}))}>정말 삭제하시겠습니까?</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_03"}))}>삭제되었습니다.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_04"}))}>필수 항목이 입력되지 않았습니다. 다시 확인해주세요.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:2, show:true, text:"Alert_05", okfunc:"Alert_05"}))}>작성 중인 내용이 삭제됩니다. 정말 나가시겠습니까?</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_06"}))}>수정되었습니다.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:2, show:true, text:"Alert_07", okfunc:"Alert_07"}))}>수정 중이던 내용이 저장되지 않습니다. 정말 나가시겠습니까?</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:2, show:true, text:"Alert_08", okfunc:"Alert_08"}))}>수정 중이던 내용이 저장되지 않습니다. 정말 취소하시겠습니까?</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_09"}))}>추가되었습니다.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_10"}))}>저장되었습니다.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_11"}))}>계정 정보가 정확하지 않습니다. 다시 확인해 주세요.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_12"}))}>일일 리포트 데이터가 없습니다.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_13"}))}>등록된 커리큘럼 데이터가 있습니다. 커리큘럼 관리를 통해 수정해주세요.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:1, show:true, text:"Alert_14"}))}>등록된 커리큘럼 데이터가 없습니다. 커리큘럼 등록 후 관리가 가능합니다.</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:2, show:true, text:"Alert_15", okfunc:"Alert_15"}))}>예약 오픈이 활성화 된 상태입니다. 정말 변경하시겠습니까?</Button>
            
            <strong>etc Popup</strong>
            <Button onClick={() => dispatch(ModalsValue({type:'basics', show:true, text:"OpenSettingsPasswordModals", okfunc:"On", date:"2023.11.11 12:00:00"}))}>오픈 설정<FiberManualRecordIcon style={{ fontSize: '11px', marginLeft: '8px', color: 'green' }} /></Button>
            <Button onClick={() => dispatch(ModalsValue({type:'basics', show:true, text:"OpenSettingsPasswordModals", okfunc:"Off", date:"2023.12.12 11:11:11"}))}>오픈 설정<FiberManualRecordIcon style={{ fontSize: '11px', marginLeft: '8px', color: 'red' }} /></Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:'basics', show:true, ReserveType:"도슨트 프로그램", text:"CurriculumReservationListModals", date:"2023.12.12"}))}>도슨트 프로그램 0/20</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:'basics', show:true, ReserveType:"부띠크 방문", text:"CurriculumReservationListModals", date:"2023.12.12", time:"01:00"}))}>부띠크 방문 0/12</Button>
            <Button type="primary" onClick={() => dispatch(ModalsValue({type:'basics', show:true, ReserveType:"CAFÉ DIOR", text:"CurriculumReservationListModals", date:"2023.12.12", time:"02:00"}))}>CAFÉ DIOR 0/24</Button>
          </li>
        </ul>
      </>
    :
      <></>
  )
}

export default PageLink;