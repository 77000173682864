import React from "react";
import { Link } from "react-router-dom";

import dayjs from "dayjs";

// style
import Style from "./index.module.scss";

// mui
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";

const TableList = (props) => {
	let dataTitleLength = props.dataTitle.length - 1;

	const titleCell = () => {
		let array = [];
		for (let i = 0; i <= dataTitleLength; i++) {
			array.push(
				<TableCell align="left" key={i}>
					{props.dataTitle[i]}
				</TableCell>
			);
		}
		return array;
	};
	const parse = (time) => {
		// console.log(time)
		// console.log(new Date(time).toISOString())
		// console.log(new Date(time))
		// console.log(new Date())
	};
	return (
		<>
			<TableContainer
				component={Paper}
				className={
					props.TableListModals
						? Style["TableContainerModals"]
						: Style["TableContainer"]
				}
			>
				{props.tableTitle ? (
					<div className={Style["tableTitle"]}>
						{props.tableTitle}
					</div>
				) : (
					<></>
				)}
				<Table
					sx={{ minWidth: 650 }}
					size="small"
					aria-label="a dense table"
				>
					<TableHead>
						<TableRow>{titleCell()}</TableRow>
					</TableHead>
					<TableBody>
						{props.listName === "ReserveList" ? (
							props.data
								.slice(props.offset, props.offset + props.limit)
								.map((data, index) => (
									<TableRow
										key={`${data.reservationType}_${dayjs(
											new Date(data.visitDate)
										).format("YYYY-MM-DD")}_${
											data.reservationDate
										}`}
										sx={{
											"&:last-child td, &:last-child th":
												{ border: 0 },
										}}
									>
										<TableCell align="left">
											{props.data.length -
												props.data.findIndex(
													(row) => row === data
												)}
										</TableCell>
										<TableCell align="left">
											{data.name}
										</TableCell>
										<TableCell align="left">
											{data.contact}
										</TableCell>
										<TableCell align="left">
											{data.reservationType ===
											"docent" ? (
												"도슨트 프로그램"
											) : data.reservationType ===
											  "boutique" ? (
												"부띠크 방문"
											) : data.reservationType ===
											  "cafe" ? (
												"CAFÉ DIOR"
											) : data.reservationType ===
											  "ladydior" ? (
												"LADY DIOR"
											) : (
												<></>
											)}
										</TableCell>
										<TableCell align="left">
											{dayjs(
												new Date(data.visitDate)
											).format("YYYY-MM-DD")}
										</TableCell>
										<TableCell align="left">
											{data.visitTime}
										</TableCell>
										<TableCell align="left">
											{data.visitePerson}
										</TableCell>
										<TableCell align="left">
											{parse(data.reservationDate)}
											{dayjs(
												new Date(data.reservationDate)
											).format("YYYY-MM-DD HH:mm")}
										</TableCell>
										<TableCell align="left">
											{data.cancelDate === "-"
												? "-"
												: dayjs(
														new Date(
															data.cancelDate
														)
												  ).format("YYYY-MM-DD HH:mm")}
										</TableCell>
										<TableCell align="left">
											{data.reservationState === "Y" ? (
												<i
													className={
														Style["DateVisitOn"]
													}
												>
													예약
												</i>
											) : (
												<i
													className={
														Style["DateVisitOff"]
													}
												>
													취소
												</i>
											)}
										</TableCell>
                                        <TableCell align="left">
											{data.isCheckin}
										</TableCell>
									</TableRow>
								))
						) : props.listName === "Notice" ? (
							props.data
								.slice(props.offset, props.offset + props.limit)
								.map((data, index) => (
									<TableRow
										key={data.idx}
										sx={{
											"&:last-child td, &:last-child th":
												{ border: 0 },
										}}
									>
										<TableCell align="left">
											{props.data.length -
												props.data.findIndex(
													(row) => row === data
												)}
										</TableCell>
										<TableCell align="left">
											<Link
												to={`View/${data.idx}`}
												state={{ Key: data.idx }}
											>
												{data.title}
											</Link>
										</TableCell>
										<TableCell
											align="left"
											className={Style["NoticeText"]}
										>
											<Link
												to={`View/${data.idx}`}
												state={{ Key: data.idx }}
											>
												{data.contents}
											</Link>
										</TableCell>
										<TableCell align="left">
											{data.visibleState === "Y" ? (
												<i
													className={
														Style["DateVisitOn"]
													}
												>
													노출
												</i>
											) : (
												<i
													className={
														Style["DateVisitOff"]
													}
												>
													비노출
												</i>
											)}
										</TableCell>
										<TableCell align="left">
											{data.regDate}
										</TableCell>
									</TableRow>
								))
						) : props.listName === "TextManagement" ? (
							props.data.map((data) => (
								<TableRow
									key={data.idx}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
									}}
								>
									<TableCell align="left">
										<Link
											to={`View/${data.idx}`}
											state={{
												Key: data.idx,
												Title: data.title,
											}}
										>
											{data.title}
										</Link>
									</TableCell>
									<TableCell align="left">
										{data.updateDate}
									</TableCell>
								</TableRow>
							))
						) : props.listName === "OpenSettings" ? (
							props.data
								.slice(props.offset, props.offset + props.limit)
								.map((data, index) => {
									return (
										<TableRow
											key={index}
											sx={{
												"&:last-child td, &:last-child th":
													{ border: 0 },
											}}
										>
											<TableCell align="left">
												{props.data.length -
													props.data.findIndex(
														(row) => row === data
													)}
											</TableCell>
											<TableCell align="left">
												{data.openMonth}
											</TableCell>
											<TableCell align="left">
												{data.start_datetime} ~{" "}
												{data.end_datetime}
											</TableCell>
											<TableCell align="left">
												{data.update_datetime}
											</TableCell>
										</TableRow>
									);
								})
						) : props.listName === "CurriculumReservationList" ? (
							props.data
								.slice(props.offset, props.offset + props.limit)
								.map((data, index) => (
									<TableRow
										key={index}
										sx={{
											"&:last-child td, &:last-child th":
												{ border: 0 },
										}}
									>
										<TableCell align="left">
											{props.data.length -
												props.data.findIndex(
													(row) => row === data
												)}
										</TableCell>
										<TableCell align="left">
											{data.name}
										</TableCell>
										<TableCell align="left">
											{data.contact}
										</TableCell>
										<TableCell align="left">
											{data.reservationType ===
											"docent" ? (
												"도슨트 프로그램"
											) : data.reservationType ===
											  "boutique" ? (
												"부띠크 방문"
											) : data.reservationType ===
											  "cafe" ? (
												"CAFÉ DIOR"
											) : data.reservationType ===
											  "ladydior" ? (
												"LADY DIOR"
											) : (
												<></>
											)}
										</TableCell>
										<TableCell align="left">
											{data.visitDate}
										</TableCell>
										<TableCell align="left">
											{data.visitTime}
										</TableCell>
										<TableCell align="left">
											{data.visitePerson}
										</TableCell>
									</TableRow>
								))
						) : (
							<></>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default TableList;
