import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

// project import
import BasicsModals from './BasicsModals';
import ConfirmModals from './ConfirmModals';

const Modals = () => {
  const count = useSelector((state) => state.ModalsReducers.ModalsValue);
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    if(!count) return;
    setOpen(count.show);
  },[count]);

  return (
    <>
      {count.type === 'basics' ? (
        <BasicsModals setOpen={setOpen} open={open} />
      ):(
        <ConfirmModals setOpen={setOpen} open={open} />
      )}
    </>
  )
}

export default Modals;