import { useRoutes } from 'react-router-dom';

// project import
import loginRoutes from 'routes/loginRoutes';
import adminRoutes from 'routes/adminRoutes';

const View =()=> {
  return useRoutes([
    loginRoutes,
    adminRoutes
  ]);
};

export default View;