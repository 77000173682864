import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reservationTypeList } from 'lib/Api';
import dayjs from 'dayjs';

// style
import Style from './index.module.scss';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// project import
import TableList from 'components/items/TableList';
import Pagination from 'components/items/Pagination';

// mui
// import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const dataTitle = [
  'No', 
  '이름', 
  '연락처', 
  '예약종류', 
  '예약일자', 
  '예약시간',
  '방문인원'
];

const CurriculumReservationListModals = () => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.ModalsReducers.ModalsValue);
  const [boardList, setBoardList] = useState([]);

  const limit = 10;
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const ApiCall = async () => {
    // console.log(count)

    try {
      let formData = {};
      formData.ReserveCode = count.ReserveCode
      formData.date = count.date
      if(count.time){
        formData.time = count.time
      }
      const _data = await reservationTypeList(formData);

      console.log(_data);

      //dayjs(new Date(data.reservationDate)).format('YYYY-MM-DD HH:mm')
      const tempArr = _data.data.reservationUsers.map((item) => {
        item.visitDate = dayjs(new Date(item.visitDate)).format('YYYY-MM-DD')
        return item;
      })
      //console.log(tempArr)
      setBoardList(tempArr);
    }catch(e){
      console.log(e);
    }
  };

  useEffect(()=>{ApiCall()},[]);

  return (
    <>
      <div className={Style['title']}>{count.ReserveType} 예약자</div>      
      <TableList 
        listName="CurriculumReservationList" 
        dataTitle={dataTitle} 
        data={boardList} 
        TableListModals={true}
        offset={offset} limit={limit} 
      />

      <Pagination 
        total={boardList.length}
        limit={limit}
        setPage={setPage}
      />

      <div className={Style['btnBox']}>
        <Button type="primary" onClick={() => dispatch(ModalsValue({show:false}))}>닫기</Button>
      </div>
    </>
  )
}

export default CurriculumReservationListModals;