import React, { useState, useEffect } from 'react';

// style
import Style from './index.module.scss';

// mui
import { InputLabel } from '@mui/material';
import { Row, Col, Form, Button, InputNumber, Checkbox, Space } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const CurriculumRegistrationFrom = ({ CurriculumType, timeFun })=> {
  const [checkedList, setCheckedList] = useState([]);
  
  const ReservationTeamCheck = (value) => {
    if (checkedList.includes(value)) {
      setCheckedList(checkedList.filter((item) => item !== value));
    } else {
      setCheckedList([...checkedList, value]);
    }
  };
  let interval = 30; //시간 간격을 입력 (단위: 분, 1시간단위로 할경우 60)
  if(interval == 0) interval = 60; //실수로 0 을입력하면 60으로 치환
  const startTime = 1000; //예약 오픈 시간 
  const endTime = 2100; //예약 종료 시간
  let timeList = [];
  for(let currTime=startTime; currTime<=endTime; currTime+=interval ) {
    if((currTime%100) >= 60) { //60분을초과함
      currTime = currTime - (currTime%100);
      currTime = currTime + 100;
    }
    //화면에 보일 시간 생성
    let appearTime = parseInt((currTime/100)).toString().padStart(2,"0") + ":" + (currTime%100).toString().padStart(2,"0");
    timeList.push(appearTime);
  }

  
  // const timeList = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00','21:00'];
  const buttons = timeList.map((item, index) => (
    <Button
      key={index}
      type={checkedList.includes(item) ? 'primary' : 'default'}
      onClick={() => ReservationTeamCheck(item)}
    >
      {item}
    </Button>
  ));

  useEffect(()=>{
    timeFun(CurriculumType, checkedList);
  },[checkedList])

  return (
    <>
      <Row align="bottom">
        <Col span={8}>
          <InputLabel>예약 가능한 팀 수</InputLabel>
          <Form.Item
            name={
              CurriculumType ==='docent' ? 'DocentTeam':
              CurriculumType ==='boutique' ? 'BoutiqueTeam':
              CurriculumType ==='cafe' ? 'CafeTeam':
              CurriculumType ==='ladydior' ? 'LadyDiorTeam':''
            }
          ><InputNumber min={0} className={Style['InputNumber']} /></Form.Item>
        </Col>
        <Col xs={{ span: 8, offset: 1 }}>
          <InputLabel>팀당 가능한 인원 수</InputLabel>
          <Form.Item
            name={
              CurriculumType ==='docent' ? 'DocentJoin':
              CurriculumType ==='boutique' ? 'BoutiqueJoin':
              CurriculumType ==='cafe' ? 'CafeJoin':
              CurriculumType ==='ladydior' ? 'LadyDiorJoin':''
            }
          ><InputNumber min={0} className={Style['InputNumber']} /></Form.Item>
        </Col>
        <Col xs={{ span: 6, offset: 1 }}>
          <Form.Item 
            name={
              CurriculumType ==='docent' ? 'DocentWeekend':
              CurriculumType ==='boutique' ? 'BoutiqueWeekend':
              CurriculumType ==='cafe' ? 'CafeWeekend':
              CurriculumType ==='ladydior' ? 'LaydDiorWeekend':''
            }
            valuePropName="checked"
          ><Checkbox>주말 제외</Checkbox></Form.Item>
        </Col>
      </Row>
      <Row className={Style['ReservationTeam']}>
        <Col span={24}>
          <div className={Style['title']}>예약 시간 <ClockCircleOutlined /></div>
          
          <Form.Item name={
              CurriculumType ==='docent' ? 'DocentTime':
              CurriculumType ==='boutique' ? 'BoutiqueTime':
              CurriculumType ==='cafe' ? 'CafeTime':
              CurriculumType ==='ladydior' ? 'LadyDiorTime':''
            }
            valuePropName="checked"
          >
            <Space size={[8, 16]} wrap>
              {buttons}
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
};

export default CurriculumRegistrationFrom;