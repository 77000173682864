// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLink_PageLink__3u8xL {\n  padding: 0;\n  margin: 0;\n  padding-top: 30px;\n}\n.PageLink_PageLink__3u8xL li {\n  padding: 0;\n  margin: 0;\n}\n.PageLink_PageLink__3u8xL li.PageLink_PageList__1dAPS p {\n  padding: 5px 50px;\n}\n.PageLink_PageLink__3u8xL li.PageLink_PageList__1dAPS p a {\n  color: #000;\n}\n.PageLink_PageLink__3u8xL li.PageLink_PageList__1dAPS p a:hover {\n  font-weight: 700;\n}\n.PageLink_PageLink__3u8xL li.PageLink_PopupList__1EHX5 {\n  padding: 0 50px;\n  border-top: 1px solid #ddd;\n}\n.PageLink_PageLink__3u8xL li.PageLink_PopupList__1EHX5 strong {\n  display: block;\n  padding: 20px 0;\n}\n.PageLink_PageLink__3u8xL li.PageLink_PopupList__1EHX5 button {\n  margin: 10px;\n}", "",{"version":3,"sources":["webpack://src/pages/PageLink.module.scss"],"names":[],"mappings":"AAAA;EAAU,UAAA;EAAU,SAAA;EAAS,iBAAA;AAI7B;AAHE;EAAG,UAAA;EAAU,SAAA;AAOf;AALM;EAAE,iBAAA;AAQR;AAPQ;EAAE,WAAA;AAUV;AATU;EAAQ,gBAAA;AAYlB;AARI;EAAY,eAAA;EAAe,0BAAA;AAY/B;AAXM;EAAO,cAAA;EAAc,eAAA;AAe3B;AAdM;EAAO,YAAA;AAiBb","sourcesContent":[".PageLink{padding:0;margin:0;padding-top:30px;\n  li{padding:0;margin:0;\n    &.PageList{\n      p{padding:5px 50px;\n        a{color:#000;\n          &:hover{font-weight:700;}\n        }\n      }\n    }\n    &.PopupList{padding:0 50px;border-top:1px solid #ddd;\n      strong{display:block;padding:20px 0;}\n      button{margin:10px;}\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageLink": "PageLink_PageLink__3u8xL",
	"PageList": "PageLink_PageList__1dAPS",
	"PopupList": "PageLink_PopupList__1EHX5"
};
export default ___CSS_LOADER_EXPORT___;
