import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// store
import { PageValue } from 'store/reducers/PageInfoReducers';

// project import
import AdminLayout from "layouts/AdminLayout";

const LayoutIndex =({ layout = 'blank' })=> {
  const dispatch = useDispatch();
  let location = useLocation();

  const pageChangeFuntion =()=>{
    if(!location.pathname) return;
    const locationHook = location.pathname.split('/');
    switch(locationHook[1]){
      case "Login":
        dispatch(PageValue({ key:"0" }));
        return;
      case 'ReserveCalendar': 
        dispatch(PageValue({ key:"1" }));
        return;
      case 'ReserveList': 
        dispatch(PageValue({ key:"2" }));
        return;
      case 'Notice': 
        dispatch(PageValue({ key:"3" }));
        return;
      case 'TextManagement': 
        dispatch(PageValue({ key:"4" }));
        return;
      default:
        dispatch(PageValue({ key:"5" }));
        return ;
    }
  }
  useEffect(pageChangeFuntion,[location.pathname]);

  return(
    <>
      {(layout === 'blank' || layout === 'login') && (
        <>
          <Outlet />
        </>
      )}
      {(layout === 'blank' || layout === 'admin') && (
        <>
          <AdminLayout />
        </>
      )}
      {layout === 'blank' && <Outlet />}
    </>
  );
};

export default LayoutIndex;