import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import * as serviceWorker from 'serviceWorker';

// library
import {Provider} from 'react-redux';
import {BrowserRouter} from "react-router-dom";
import {SkeletonTheme} from 'react-loading-skeleton';

// project import
import ScrollTop from 'components/ScrollTop';
import Modals from 'components/items/Modals';

// utils
import {store} from 'store';
import 'utils/locales/i18n';

// css
import 'react-loading-skeleton/dist/skeleton.css';

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development') {
    const { worker } = require('./mocks/browser')
    worker.start()
}

ReactDOM.render(
  <Provider store={store}>
    <SkeletonTheme baseColor='#91EAE4' highlightColor='#f5f5f5'>
      <BrowserRouter>
        <ScrollTop />
        <App />
        <Modals />
      </BrowserRouter>
    </SkeletonTheme>
  </Provider>
, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();