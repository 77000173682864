// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomToolbar_btnBox__1skV2 {\n  margin-bottom: 1.5rem;\n}\n.CustomToolbar_btnBox__1skV2 .CustomToolbar_ButtonPrev__262An {\n  color: #71869d;\n}\n.CustomToolbar_btnBox__1skV2 .CustomToolbar_ButtonNext__1Aa2A {\n  color: #71869d;\n}\n.CustomToolbar_btnBox__1skV2 .CustomToolbar_formattedLabel__3oyA6 {\n  font-size: 1.14844rem;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://src/components/items/Calendar/CustomToolbar/index.module.scss"],"names":[],"mappings":"AAAA;EAAQ,qBAAA;AAER;AADE;EAAY,cAAA;AAId;AAHE;EAAY,cAAA;AAMd;AALE;EAAgB,qBAAA;EAAqB,gBAAA;AASvC","sourcesContent":[".btnBox{margin-bottom:1.5rem;\n  .ButtonPrev{color:#71869d;}\n  .ButtonNext{color:#71869d;}\n  .formattedLabel{font-size:1.14844rem;font-weight:600;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnBox": "CustomToolbar_btnBox__1skV2",
	"ButtonPrev": "CustomToolbar_ButtonPrev__262An",
	"ButtonNext": "CustomToolbar_ButtonNext__1Aa2A",
	"formattedLabel": "CustomToolbar_formattedLabel__3oyA6"
};
export default ___CSS_LOADER_EXPORT___;
