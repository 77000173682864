// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".View_FromWrap__31EG5 {\n  padding: 1.3125rem 1.3125rem;\n  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;\n  border-radius: 0.75rem !important;\n  box-shadow: 0 0.375rem 0.75rem rgba(140, 152, 164, 0.075) !important;\n}", "",{"version":3,"sources":["webpack://src/pages/TextManagement/View/index.module.scss"],"names":[],"mappings":"AAAA;EAAU,4BAAA;EAA6B,2DAAA;EAAuD,iCAAA;EAAiC,oEAAA;AAK/H","sourcesContent":[".FromWrap{padding: 1.3125rem 1.3125rem;border:0.0625rem solid rgba(231,234,243,.7) !important;border-radius:0.75rem !important;box-shadow:0 0.375rem 0.75rem rgba(140,152,164,.075) !important;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FromWrap": "View_FromWrap__31EG5"
};
export default ___CSS_LOADER_EXPORT___;
