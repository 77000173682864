import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

// style
import Style from './index.module.scss';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// 연동
import {openLogin} from 'lib/Api';

//lib
import { encrypt } from 'lib/crypto';

// mui
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Button, Input, Typography } from 'antd';
const { Text } = Typography;

const OpenSettingsPasswordModals = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const count = useSelector((state) => state.ModalsReducers.ModalsValue);

  const onFinish = (values) => {
    // console.log('Success:', values);
    const sendData = {'value':encrypt(values.password)};
    openLogin(sendData, onResult);
    
  };

  const onResult = (_data) =>{
    const resultData = _data.data
    if(resultData.statusCode !== 200){
      alert(resultData.error)
      return;
    }else{
      dispatch(ModalsValue({show:false}));
      navigate('/ReserveCalendar/OpenSettings', {state:{active: count.okfunc, date: count.date }});
    }
    
  }
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <div className={Style['title']}>오픈 설정</div>
      <Text className={Style['textSecondary']} type="secondary"><InfoCircleOutlined /> 오픈 설정을 위해 패스워드를 입력해주세요</Text>
      <Form
        className={Style['FormBox']}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: '비밀번호를 확인해주세요.' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item className={Style['btnBox']}>
          <Button type="primary" htmlType="submit">
            확인
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default OpenSettingsPasswordModals;