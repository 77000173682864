import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { textBoardList } from 'lib/Api';
import { addToZero } from 'lib/common';

// project import
import TableList from 'components/items/TableList';

const dataTitle = [
  '구분', 
  '최근 업데이트'
]

const TextManagement = (props) => {
  const navigate = useNavigate();

  const [boardList, setBoardList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const textListLoad = async () => {
    try {
      setError(null); // error 초기화
      setLoading(true); // loading 상태 변경
      const _data = await textBoardList();
      if(_data.data.statusCode === 200){
        const parseBoard = _data.data.boardData.map((item) => {
          const tempDate = new Date(item.updateDate)
          item.updateDate = `${tempDate.getFullYear()}-${addToZero(tempDate.getMonth()+1,'00')}-${addToZero(tempDate.getDate(),'00')} ${addToZero(tempDate.getHours(),'00')}:${addToZero(tempDate.getMinutes(),'00')}`
          return item;
        })
        setBoardList(parseBoard);
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
      
    }catch(e){
      setError(e);
    }
    setLoading(false);
  };

  useEffect(()=>{textListLoad()},[]);

  if (loading) return <div>로딩중..</div>; 
  if (error) return <div>에러가 발생했습니다</div>;
  if (!boardList) return null;
  
  return (
    <>
      <Helmet>
        <title>{props.metaTitle}</title>
      </Helmet>
      <h2 className='contentsTitle'>{props.metaTitle}</h2>

      <TableList listName="TextManagement" dataTitle={dataTitle} data={boardList} />
    </>
  )
}

export default TextManagement;