import axios from 'axios';


export const userData = async () => {
  return axios.get(returnInterfaceURL("userData"), createAxiosConfix(''));
}

export const adminLogin = async (_data, returnFun) => {
  const _returnData = await axios.post(returnInterfaceURL("cms/admin/login"),_data, createAxiosConfix('login'));
  returnFun(_returnData);
}

export const adminLogout = async () => {
  return axios.get(returnInterfaceURL("cms/admin/logout"),createAxiosConfix(''));
}

export const openInfo = async (data) => {
  return axios.post(returnInterfaceURL("cms/reservation/openInfo"), data, createAxiosConfix(''));
}

export const checkReport = async (data) => {
  return axios.post(returnInterfaceURL("cms/report/checkReport"), data, createAxiosConfix(''));
}

export const reservationDayList = async (data) => {
  return axios.post(returnInterfaceURL("cms/reservation/dayList"), data, createAxiosConfix(''));
}

export const reservationMonthList = async (data) => {
  return axios.post(returnInterfaceURL("cms/reservation/monthList"), data, createAxiosConfix(''));
}

export const checkCurriculum = async (data) => {
  return axios.post(returnInterfaceURL("cms/reservation/checkCurriculum"), data,createAxiosConfix(''));
}

export const curriculumRegistration = async (data) => {
  return axios.post(returnInterfaceURL("cms/curriculum/registration"), data,createAxiosConfix(''));
}

export const curriculumDataLoad = async (data) => {
  return axios.post(returnInterfaceURL("cms/curriculum/dataLoad"),data,createAxiosConfix(''));
}

export const curriculumDataSave = async (data) => {
  return axios.post(returnInterfaceURL("cms/curriculum/dataSave"),data,createAxiosConfix(''));
}

export const openLogin = async (_data, returnFun) => {
  const _returnData = await axios.post(returnInterfaceURL("cms/open/login"),_data, createAxiosConfix('login'));
  returnFun(_returnData);
}

export const openDefaultInfo = async () => {
  return axios.post(returnInterfaceURL("cms/open/defaultInfo"),createAxiosConfix(''));
}

export const openInfoDataSave = async (_data) => {
  return axios.post(returnInterfaceURL("cms/open/saveInfo"), _data, createAxiosConfix(''));
}

export const openHistoryList = async () => {
  return axios.post(returnInterfaceURL("cms/open/history"),createAxiosConfix(''));
}

export const reservationTypeList = async (_data) => {
  return axios.post(returnInterfaceURL("cms/reservation/typeList"), _data,createAxiosConfix(''));
}

export const reservationList = async () => {
  return axios.post(returnInterfaceURL("cms/reservation/list"),createAxiosConfix(''));
}

export const noticeBoardList = async () => {
  return axios.post(returnInterfaceURL("cms/board/list"), createAxiosConfix(''));
}

export const boardRegistration = async (data) => {
  return axios.post(returnInterfaceURL("cms/board/registration"),data, createAxiosConfix(''));
}

export const boardDetail = async (data) => {
  return axios.post(returnInterfaceURL("cms/board/detail"),data, createAxiosConfix(''));
}

export const boardEdit = async (data) => {
  return axios.post(returnInterfaceURL("cms/board/edit"),data, createAxiosConfix(''));
}

export const textBoardList = async () => {
  return axios.post(returnInterfaceURL("cms/textBoard/list"),createAxiosConfix(''));
}

export const textBoardRegistration = async (data) => {
  return axios.post(returnInterfaceURL("cms/textBoard/registration"),data ,createAxiosConfix(''));
}

export const textBoardDetail = async (data) => {
  return axios.post(returnInterfaceURL("cms/textBoard/detail"),data, createAxiosConfix(''));
}

export const adminAdd = async (data) => {
  return axios.post(returnInterfaceURL("cms/admin/adminAdd"),data, createAxiosConfix(''));
}
export const managerPassInsert = async (data) => {
  return axios.post(returnInterfaceURL("cms/open/openManagerAdd"),data, createAxiosConfix(''));
}

const createAxiosConfix = (page) => {
  let axiosConfig;
  // console.log(page)
  if(page === 'login'){
    axiosConfig = {
      headers:{
        "Content-Type": "application/json;charset=UTF-8"
      }
    }
  }else{
    axiosConfig = {
      headers:{
        "Content-Type": "application/json;charset=UTF-8",
        "token":sessionStorage.getItem("token")
      }
    }
  }
  // console.log(axiosConfig)
  return axiosConfig;
}
const returnInterfaceURL = (_url) => {
    // 실 서버 용
    return process.env.REACT_APP_BASEURL+_url
};