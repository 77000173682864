// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OpenSettingsPasswordModals_title__12szy {\n  padding-top: 3rem;\n  font-size: 1.41094rem;\n  line-height: 1.5;\n  font-weight: 600;\n  text-align: center;\n}\n\n.OpenSettingsPasswordModals_textSecondary__2d359 {\n  display: block;\n  padding: 4rem 0 3rem;\n  font-size: 0.98438rem;\n  text-align: center;\n  color: #8c98a4;\n}\n\n.OpenSettingsPasswordModals_FormBox__228LI > div > div > div {\n  padding-bottom: 0 !important;\n}\n.OpenSettingsPasswordModals_FormBox__228LI > div > div > div label {\n  padding-bottom: 4px;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.88);\n}\n.OpenSettingsPasswordModals_FormBox__228LI .OpenSettingsPasswordModals_btnBox__3Zjke {\n  padding-top: 2rem;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/ReserveCalendar/OpenSettingsPasswordModals/index.module.scss"],"names":[],"mappings":"AAAA;EAAO,iBAAA;EAAiB,qBAAA;EAAqB,gBAAA;EAAgB,gBAAA;EAAgB,kBAAA;AAM7E;;AALA;EAAe,cAAA;EAAc,oBAAA;EAAoB,qBAAA;EAAqB,kBAAA;EAAkB,cAAA;AAaxF;;AATM;EAAM,4BAAA;AAaZ;AAZQ;EAAM,mBAAA;EAAmB,eAAA;EAAe,0BAAA;AAiBhD;AAbE;EAAQ,iBAAA;EAAiB,kBAAA;AAiB3B","sourcesContent":[".title{padding-top:3rem;font-size:1.41094rem;line-height:1.5;font-weight:600;text-align:center;}\n.textSecondary{display:block;padding:4rem 0 3rem;font-size:0.98438rem;text-align:center;color:#8c98a4;}\n.FormBox{\n  > div{\n    > div{\n      > div{padding-bottom:0 !important;\n        label{padding-bottom:4px;font-size:14px;color:rgba(0, 0, 0, 0.88);}\n      }\n    }\n  }\n  .btnBox{padding-top:2rem;text-align:center;}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "OpenSettingsPasswordModals_title__12szy",
	"textSecondary": "OpenSettingsPasswordModals_textSecondary__2d359",
	"FormBox": "OpenSettingsPasswordModals_FormBox__228LI",
	"btnBox": "OpenSettingsPasswordModals_btnBox__3Zjke"
};
export default ___CSS_LOADER_EXPORT___;
