// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notice_searchFrom__1bc3I {\n  padding: 1.3125rem 1.3125rem;\n  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;\n  border-radius: 0.75rem !important;\n  box-shadow: 0 0.375rem 0.75rem rgba(140, 152, 164, 0.075) !important;\n}\n.Notice_searchFrom__1bc3I div div label {\n  padding-bottom: 4px;\n  font-size: 14px;\n  color: rgba(0, 0, 0, 0.88);\n}\n\n.Notice_noticeWriteRow__1ZNVL {\n  margin: 3rem 0 1.3125rem;\n}", "",{"version":3,"sources":["webpack://src/pages/Notice/index.module.scss"],"names":[],"mappings":"AAAA;EAAY,4BAAA;EAA4B,2DAAA;EAAuD,iCAAA;EAAiC,oEAAA;AAKhI;AAFM;EAAM,mBAAA;EAAmB,eAAA;EAAe,0BAAA;AAO9C;;AAHA;EAAgB,wBAAA;AAOhB","sourcesContent":[".searchFrom{padding:1.3125rem 1.3125rem;border:0.0625rem solid rgba(231,234,243,.7) !important;border-radius:0.75rem !important;box-shadow:0 0.375rem 0.75rem rgba(140,152,164,.075) !important;\n  div{\n    div{\n      label{padding-bottom:4px;font-size:14px;color:rgba(0, 0, 0, 0.88);}\n    }\n  }\n}\n.noticeWriteRow{margin:3rem 0 1.3125rem;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchFrom": "Notice_searchFrom__1bc3I",
	"noticeWriteRow": "Notice_noticeWriteRow__1ZNVL"
};
export default ___CSS_LOADER_EXPORT___;
