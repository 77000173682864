import React, {useState, useEffect} from 'react';
import { Helmet } from "react-helmet";
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";

// style
import Style from './index.module.scss';

// mui
import { InputLabel } from '@mui/material';
import { Form, Button, Input } from 'antd';

// 연동
import {adminLogin} from 'lib/Api';

//lib
import { encrypt } from 'lib/crypto';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// assets
import { LoginOutlined } from '@ant-design/icons';

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loadings, setLoadings] = useState([]);
  
  const onFinish = (values) => {
    const sendData = {'value':encrypt(values.password)};
    adminLogin(sendData, onResult);
  };
  const onResult = (_data) =>{
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[1] = false;
      return newLoadings;
    });
    const resultData = _data.data
    if(resultData.statusCode !== 200){
      //alert(resultData.error)
      dispatch(ModalsValue({type:1, show:true, text:"Alert_11"}))
      return;
    }else{
      //console.log('token',resultData.token)
      sessionStorage.setItem("token", resultData.token);
      navigate("/ReserveList");
    }
    
  }
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[1] = false;
      return newLoadings;
    });
  };

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
  };

  useEffect(() => {
    console.log("test");
  }, []);

  
  return (
    <>
      <Helmet>
        <title>{props.metaTitle}</title>
      </Helmet>

      <div className={Style['login']}>
        <div className={Style['inner']}>
          <div className={Style['title']}>Dior CMS</div>
          <Form className={Style['from']}
            name="basic"
            labelCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <strong>로그인</strong>
            <InputLabel>password</InputLabel>
            <Form.Item
              className={Style['FormItem']}
              name="password"
              rules={[{ required: true, message: '비밀번호를 확인해주세요.' }]}
            >
              <Input.Password placeholder="password" size="large" className={Style['InputPassword']} />
            </Form.Item>

            <Form.Item>
              <Button  className={Style['ButtonLogin']} size="large" type="primary" htmlType="submit" icon={<LoginOutlined />} loading={loadings[1]} onClick={() => enterLoading(1)}>
                로그인
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className={Style['bg']}>
          <div>
            <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
              <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
            </svg>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;