import { rest } from 'msw';

export const handlers = [
  // rest.get('http://localhost:8090/cms/curriculum/dataLoad', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       message: "",
  //       statusCode: 200,
  //       error: "",
  //       reservationTimes:{
  //         "11" : {team:20, person:2, reserveBool:true,editable:true},
  //         "12" : {team:20, person:2, reserveBool:true},
  //         "13" : {team:20, person:2, reserveBool:true,editable:true},
  //         "15" : {team:20, person:2, reserveBool:false},
  //         "16" : {team:10, person:4, reserveBool:true,editable:true},
  //         "17" : {team:20, person:2, reserveBool:true}
  //       }
  //     })
  //   )
  // }),
  // rest.get('http://localhost:8090/cms/open/history', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       message: "",
  //       statusCode: 200,
  //       error: "",
  //       historyData:[
  //         {openMonth:'2023-03', reservationStartDate:'2023-02-24 12:00', reservationEndDate:'2023-03-16 12:00', updateDate:'2023-03-02 15:37:27'},
  //         {openMonth:'2023-02', reservationStartDate:'2023-01-24 12:00', reservationEndDate:'2023-02-16 12:00', updateDate:'2023-02-02 15:37:27'},
  //         {openMonth:'2023-01', reservationStartDate:'2022-12-24 12:00', reservationEndDate:'2023-01-16 12:00', updateDate:'2023-01-02 15:37:27'},
  //         {openMonth:'2022-12', reservationStartDate:'2022-11-24 12:00', reservationEndDate:'2022-12-16 12:00', updateDate:'2022-12-22 15:37:27'},
  //       ]
  //     })
  //   )
  // }),
  // rest.get('http://localhost:8090/cms/reservation/monthList', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       api: 'monthList',
  //       message: "",
  //       statusCode: 200,
  //       error: "",
  //       curriculum:{
  //           '2023-04-01':{cafeJoin:0,docentJoin:0,boutiqueJoin:0},// 쿼리큘럼으로 등록했으나 예약 비활성화 처리 했을 경우
  //           '2023-04-02':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-03':{cafeJoin:2,cafeTeam:24,docentJoin:1,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-04':{cafeJoin:6,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-05':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-06':{cafeJoin:2,cafeTeam:24,docentJoin:1,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-07':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-08':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-09':{cafeJoin:2,cafeTeam:24,docentJoin:1,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-10':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-11':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-12':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-13':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-14':{cafeJoin:2,cafeTeam:24,docentJoin:1,docentTeam:20,boutiqueJoin:1,boutiqueTeam:12},
  //           '2023-04-15':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-16':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-17':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-18':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:1,boutiqueTeam:12},
  //           '2023-04-19':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-20':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-21':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-22':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:1,boutiqueTeam:12},
  //           '2023-04-23':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-24':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-25':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:1,boutiqueTeam:12},
  //           '2023-04-26':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-27':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-28':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-29':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12},
  //           '2023-04-30':{cafeJoin:2,cafeTeam:24,docentJoin:0,docentTeam:20,boutiqueJoin:0,boutiqueTeam:12}
  //       },
  //       changedCurriculum:{
  //           '2023-04-04':{cafeTeam:15,boutiqueTeam:10},
  //           '2023-04-11':{docentTeam:14,boutiqueTeam:10},
  //           '2023-04-23':{docentTeam:24}
  //       }
  //     })
  //   )
  // }),
  // rest.get('http://localhost:8090/cms/reservation/typeList', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       "api":"list",
  //       "message":"",
  //       "statusCode":200,
  //       "error":"",
  //       "reservationUsers":[
  //         {"idx":11,"name":"c홍길동","contact":"921000000000","reservationType":"cafe","visitDate":"2023-04-18","visitTime":"11:00","visitePerson":2,"reservationDate":"2023-03-01 13:00","reservationState":"Y"},
  //         {"idx":10,"name":"c홍길동","contact":"8210000007928","reservationType":"boutique","visitDate":"2023-04-19","visitTime":"11:00","visitePerson":2,"reservationDate":"2023-04-01 13:00","reservationState":"Y"},
  //         {"idx":9,"name":"c홍길동","contact":"821000000000","reservationType":"docent","visitDate":"2023-04-06","visitTime":"11:00","visitePerson":2,"reservationDate":"2023-05-01 13:00","reservationState":"Y"},
  //         {"idx":8,"name":"홍길동1","contact":"821000000000","reservationType":"cafe","visitDate":"2023-04-07","visitTime":"12:00","visitePerson":1,"reservationDate":"2023-03-01 13:00","cancelDate":"2023-03-01 13:00:55","reservationState":"N"}
  //       ]
  //     })
  //   )
  // }),
  // rest.get('http://localhost:8090/cms/board/detail', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       "api":"detail",
  //       "message":"",
  //       "statusCode":200,
  //       "error":"",
  //       "boardDetail":{
  //         "idx":7,
  //         "title":"mocks 3월 공지 사항",
  //         "contents":"mocks 3월 오픈 알림 공지 사항",
  //         "enTitle":"mocks Reservation schedule for March",
  //         "enContents":"mocks Reservation schedule for March Open on February 24th at noon for March...",
  //         "visibleState":"Y"
  //       }
  //     })
  //   )
  // }),
  // rest.get('http://localhost:8090/cms/board/list', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       "api":"list",
  //       "message":"",
  //       "statusCode":200,
  //       "error":"",
  //       "boardData":[
  //         {"idx":14,"title":"mocks 3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":13,"title":"mocks 3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":12,"title":"mocks 3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":11,"title":"mocks 3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":10,"title":"mocks 3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"N","regDate":"2023-02-20 12:11:00"},
  //         {"idx":9,"title":"3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":8,"title":"3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":7,"title":"3월 예약 일정안내","contents":"디올 성수 3월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":6,"title":"2월 예약 일정안내","contents":"디올 성수 2월 예약 일정안내...","visibleState":"N","regDate":"2023-02-20 12:11:00"},
  //         {"idx":5,"title":"2월 예약 일정안내","contents":"디올 성수 2월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":4,"title":"1월 예약 일정안내","contents":"디올 성수 1월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":3,"title":"1월 예약 일정안내","contents":"디올 성수 1월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":2,"title":"12월 예약 일정안내","contents":"디올 성수 12월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"},
  //         {"idx":1,"title":"12월 예약 일정안내","contents":"디올 성수 12월 예약 일정안내...","visibleState":"Y","regDate":"2023-02-20 12:11:00"}
  //       ]
  //     })
  //   )
  // }),
  // rest.get('http://localhost:8090/cms/textBoard/detail', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       "api":"detail",
  //       "message":"",
  //       "statusCode":200,
  //       "error":"",
  //       "boardData": {
  //         "idx": 1,
  //         "koTitle": "mocks 국문 타이틀 입니다.",
  //         "koContents": "mocks 국문 개인 정보 취급방침 내용 입니다.",
  //         "enTitle": "mocks 영문 타이틀 입니다.",
  //         "enContents": "mocks 영문 개인 정보 취급방침 내용 입니다."
  //       }
  //     })
  //   )
  // }),


















  
  // ex)
  // rest.get('https://testUrl.com', async(req, res, ctx) => {
  //   return res(
  //     ctx.json({
  //       "data": {
  //         "data" :
  //         [
  //           {
  //             "name": "test",
  //             "age": 1
  //           }
  //         ]
  //       }
  //     })
  //   )
  // }),
  // rest.get('https://testUrl.com?id=react', async (req, res, ctx) => {
  //   const id = req.url.searchParams.get('id')
  //   const originalResponse = await ctx.fetch(req)
  //   const originalResponseData = await originalResponse.json()
  //   return res(
  //     ctx.json({
  //       "data": {
  //         "people" :
  //         [
  //           ...originalResponseData.data.people,
  //           {
  //             "name": id,
  //             "age": 135
  //           }
  //         ]
  //       }
  //     }),
  //   )
  // }),
]
