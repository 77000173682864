import { createSlice } from '@reduxjs/toolkit';

  // 초기설정
  const initialState = {
    ModalsValue: {
      type: '', // 'basics'= 일반 팝업, 1= 확인, 2= 확인, 취소
      show: false,
      text: '', // 팝업 내용
      ReserveType:'', // 예약종류
      date:'', // 날짜
      time:'', // 시간
      okfunc: null, // 확인 호출
      nofunc: null, // 취소 호출
      handleOk: false,
      handleCancel: false,
    }
  };

  // 이벤트설정
export const ModalsReducers = createSlice({
  name: "ModalsValue",
  initialState,
  reducers: {
    ModalsValue: (state, action) => {
      state.ModalsValue = action.payload
    }
  },
});

export const { ModalsValue } = ModalsReducers.actions;
export default ModalsReducers.reducer;