import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { openHistoryList, openDefaultInfo, openInfoDataSave } from 'lib/Api';

// style
import Style from './index.module.scss';

// store
import { ModalsValue } from 'store/reducers/ModalsReducers';

// project import
import TableList from 'components/items/TableList';
import Pagination from 'components/items/Pagination';

//lib
import dayjs from 'dayjs';
import {addToZero} from 'lib/common';

// mui
import { FieldTimeOutlined } from '@ant-design/icons';
import { Form, Button, DatePicker, Space } from 'antd';
const { RangePicker } = DatePicker;


const dataTitle = [
  'No', 
  '오픈 월', 
  '예약 설정 날짜', 
  '업데이트 날짜'
]

const OpenSettings = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const monthFormat = 'YYYY/MM';
  const dateFormat = 'YYYY-MM-DD HH:mm';

  const [boardList, setBoardList] = useState([]);
  const [defaultData, setDefaultData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [defaultYear, setDefaultYear] = useState();
  const [defaultMonth, setDefaultMonth] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [updateDate, setUpdateDate] = useState();

  const limit = 10;
  const [page, setPage] = useState(1);
  const offset = (page - 1) * limit;

  const historyDataLoad = async (e) => {
    try {
      setError(null); // error 초기화
      setLoading(true); // loading 상태 변경
      const _data = await openHistoryList();
      if(_data.data.statusCode === 200){
        const parseBoard = _data.data.historyData.map((item) => {
          const _updateDate = new Date(item.update_datetime)
          const _startDate = new Date(item.start_datetime)
          const _endDate = new Date(item.end_datetime)
          item.start_datetime = `${_startDate.getFullYear()}-${addToZero(_startDate.getMonth()+1,'00')}-${addToZero(_startDate.getDate(),'00')} ${addToZero(_startDate.getHours(),'00')}:${addToZero(_startDate.getMinutes(),'00')}`
          item.end_datetime = `${_endDate.getFullYear()}-${addToZero(_endDate.getMonth()+1,'00')}-${addToZero(_endDate.getDate(),'00')} ${addToZero(_endDate.getHours(),'00')}:${addToZero(_endDate.getMinutes(),'00')}`
          item.update_datetime = `${_updateDate.getFullYear()}-${addToZero(_updateDate.getMonth()+1,'00')}-${addToZero(_updateDate.getDate(),'00')} ${addToZero(_updateDate.getHours(),'00')}:${addToZero(_updateDate.getMinutes(),'00')}`
          return item;
        })
        setBoardList(parseBoard);
      }else {
        alert(_data.data.error);
      }
    }catch(e){
      setError(e);
    }
    setLoading(false);
  };
  const openDefaultDataLoad = async (e) => {
    try {
      setError(null); // error 초기화
      setLoading(true); // loading 상태 변경
      const _data = await openDefaultInfo();
      if(_data.data.statusCode === 200){
        setDefaultData(_data.data);
      }else {
        alert(_data.data.error);
        navigate("/Login");
      }
    }catch(e){
      setError(e);
    }
    setLoading(false);
  };

  const onFinish = async (fieldsValue) => {
    const rangeValue = fieldsValue['ReservationSettings'];
    const values = {
      'openYear': fieldsValue['OpenMonth'].format('YYYY'),
      'openMonth': fieldsValue['OpenMonth'].format('MM'),
      'reservationStartYear': rangeValue[0].format('YYYY'),
      'reservationStartMonth': rangeValue[0].format('MM'),
      'reservationStartDate': rangeValue[0].format('DD'),
      'reservationStartTime': rangeValue[0].format('HH:mm'),
      'reservationEndYear': rangeValue[1].format('YYYY'),
      'reservationEndMonth': rangeValue[1].format('MM'),
      'reservationEndDate': rangeValue[1].format('DD'),
      'reservationEndTime': rangeValue[1].format('HH:mm'),
    };
    const _data = await openInfoDataSave(values)
    if(_data.data.statusCode === 200){
      alert('수정 됐습니다.')
      historyDataLoad();
      openDefaultDataLoad();
    }else {
      console.log(_data.data.error);
    }
  };
  
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const ButtonCancel =()=> {
    dispatch(ModalsValue({type:2, show:true, text:"Alert_05", okfunc:"Alert_05"}));
  };

  useEffect(()=>{
    historyDataLoad();
    openDefaultDataLoad();
  },[]);

  useEffect(()=>{
    if(!defaultData) return;
    if(defaultData.openInfo){
      setDefaultYear(defaultData.openInfo.year.toString());
    }
    if(defaultData.openInfo){
      defaultData.openInfo.month.toString().length===1 ? setDefaultMonth('0' + defaultData.openInfo.month.toString()) : setDefaultMonth(defaultData.openInfo.month.toString());
    }
    if(defaultData.reservationInfo){
      const revstartDate = new Date(defaultData.reservationInfo.startTime)
      const revendDate = new Date(defaultData.reservationInfo.end_datetime)

      let _startDate = dayjs(`${revstartDate.getFullYear()}-${addToZero(revstartDate.getMonth()+1,'00')}-${revstartDate.getDate()} ${addToZero(revstartDate.getHours(),'00')}:${addToZero(revstartDate.getMinutes(),'00')}`).format(dateFormat)
      setStartDate(_startDate)
      let _endDate = dayjs(`${revendDate.getFullYear()}-${addToZero(revendDate.getMonth()+1,'00')}-${revendDate.getDate()} ${addToZero(revendDate.getHours(),'00')}:${addToZero(revendDate.getMinutes(),'00')}`).format(dateFormat)
      setEndDate(_endDate)
      const updateDate = new Date(defaultData.updateDate)
      setUpdateDate(`${updateDate.getFullYear()}-${addToZero(updateDate.getMonth()+1,'00')}-${addToZero(updateDate.getDate(),'00')} ${addToZero(updateDate.getHours(),'00')}:${addToZero(updateDate.getMinutes(),'00')}`)
    }
    // 
  },[defaultData]);

  if (loading) return <div>로딩중..</div>; 
  if (error) return <div>에러가 발생했습니다</div>;
  if (!boardList) return null;

  return (
    <>
      <h2 className='contentsTitle'>{props.metaTitle}</h2>

      <div className={Style['textInfo']}>
        예약 오픈이 {
          (defaultData && defaultData.openState === 'Y') ? (
            <span className={Style['On']}>활성화</span>
          ):(
            <span className={Style['Off']}>비활성화</span>
          )
        } 되었습니다.
      </div>
      <div className={Style['updateInfo']}>최근 업데이트 <FieldTimeOutlined /> <span>{updateDate}</span></div>

      <div className={Style['settingsFrom']}>
        <Form
          name="wrap"
          labelCol={{ span: 5 }}
          labelAlign="left"
          colon={false}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          fields={[
            {name: ["OpenMonth"],value: defaultYear? dayjs(`${defaultYear}/${defaultMonth}`, monthFormat) : ''},
            {name: ["ReservationSettings"],value: [startDate ? dayjs(startDate, dateFormat) : '', endDate ? dayjs(endDate, dateFormat) : '']}
          ]}
        >
          <Form.Item className={Style['formItem']} label="오픈 월" name="OpenMonth" rules={[{ required: true, message: '필수 입력 입니다.' }]}>
            <DatePicker format={monthFormat} picker="month" />
          </Form.Item>
          <Form.Item className={Style['formItem']} label="예약 설정" name="ReservationSettings" rules={[{ required: true, message: '필수 입력 입니다.' }]}>
            <RangePicker use12Hours={false} format="YYYY-MM-DD HH:mm" showTime={{
              hideDisabledOptions: true,
              defaultValue: [dayjs('12:00', 'HH:mm'), dayjs('12:00', 'HH:mm')],
            }}/>
          </Form.Item>

          <Form.Item className={Style['formItem']}>
            <Space>
              <Button danger onClick={ButtonCancel}>취소</Button>
              <Button type="primary" htmlType="submit">설정하기</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      <TableList
       listName="OpenSettings" tableTitle="히스토리" dataTitle={dataTitle} 
       data={boardList} 
       offset={offset} limit={limit} 
      />

      <Pagination 
        total={boardList.length}
        limit={limit}
        setPage={setPage}
      />
    </>
  )
}

export default OpenSettings;