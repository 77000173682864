import { combineReducers } from 'redux';

// project import
import PageInfoReducers from 'store/reducers/PageInfoReducers';
import ModalsReducers from 'store/reducers/ModalsReducers';
import sampleReducers from 'store/reducers/sampleReducers';
import CalendarDateReducers from 'store/reducers/ReserveCalendar/CalendarDateReducers';
import OpenSettingReducers from 'store/reducers/ReserveCalendar/OpenSettingReducers';

const reducers = combineReducers({
  PageInfoReducers,
  ModalsReducers,
  sampleReducers,
  CalendarDateReducers,
  OpenSettingReducers
});

export default reducers;